import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { NewUser } from '../models/user.models';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
  ) { }

  getUsers(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/users/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  getUserDetails(id:any): Observable<any> {
    let url = environment.apiUrl + '/users/' + id + '/';
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  createUser(user_data:any): Observable<any> {
    let url = environment.apiUrl + '/users/';
    return this.httpClient.post(url,user_data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  editUser(user_data:any): Observable<any> {
    let url = environment.apiUrl + '/users/' + user_data.id + '/';
    return this.httpClient.put(url,user_data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  toggleIsActiveUser(user_data:any): Observable<any> {
    let url = environment.apiUrl + '/users/toogle-is-active/' + user_data.id;
    return this.httpClient.put(url,user_data).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  updateFreeSuscriptionUser(userId: number, productId: number): Observable<any> {
    const URL = `${environment.apiUrl}/free-suscription/`

    return this.httpClient.post(URL, { user: userId, product: productId }).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
}
