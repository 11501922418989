<main class="activities-page page">
  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content class=>
      <div class="row w-100">
        <h1 class="font-24 font-primary--eb">Reportes</h1>
      </div>
    </div>
  </app-card>

  <div class="mt-3 reports-options">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content class="text-center d-flex flex-column align-items-center">
        <span class="font-24 font-bold">
          Carreras Universitarias
        </span>

        <img
          src="/assets/images/icons/icon_toga.svg"
          width="150"
          height="110"
          class="mt-3"
        />

        <button
          class="bg-red btn cursor-pointer w-100"
          routerLink="/dashboard/reportes/carreras-universitarias"
        >
          <span class="font-18 text-white">
            Entrar
          </span>
        </button>
      </div>
    </app-card>

    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content class="text-center d-flex flex-column align-items-center">
        <span class="font-24 font-bold">Frases</span>

        <img
          src="/assets/images/icons/icon_phrases.svg"
          width="150"
          height="110"
          class="mt-3"
        />


        <button
          class="bg-red btn cursor-pointer w-100 mt-1"
          routerLink="/dashboard/reportes/frases"
        >
          <span class="font-18 text-white">
            Entrar
          </span>
        </button>
      </div>
    </app-card>
  </div>
</main>