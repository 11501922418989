import { Category } from './../../../models/category.model';
import { generateID } from './../../../utils/fakers';
import { ParametersService } from './../../../services/parameters.service';
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './new-parameter-page.component.html',
  styleUrls: ['./new-parameter-page.component.scss']
})
export class NewParameterPageComponent implements OnInit {
  inProcess: boolean = false
  name!: string
  category: string | null = null
  categories: Array<Category> = [
    {
      id: generateID(),
      title: 'Category 1',
      slug: 'category-1'
    },
    {
      id: generateID(),
      title: 'Category 2',
      slug: 'category-2'
    },
  ]
  errors: Array<{ title: string }> = []

  constructor(private paramsService: ParametersService) {
  }

  ngOnInit(): void {
  }
  isValidForm() {
    let isValidForm = true
    const emptyName = !this.name

    if (emptyName) {
      this.errors.push({ title: 'El campo nombre es requerido.' })
      isValidForm = false
    }

    return isValidForm
  }

  save() {
    if (this.isValidForm()) {
      this.errors = []
      const newParam = {
        name: this.name,
        category: this.category
      }
      this.paramsService.addParam(newParam).subscribe({
        complete: () => {
          console.debug('Finished')
        },
        next: (data) => {
          console.debug('Success', data)
        },
        error: (e) => {
          console.error('Ha ocurrido un error', e)
        },
      })
    }

  }

}
