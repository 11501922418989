<main class="page pages-page">
  <!-- HEADER -->
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">
              <span class="text-green font-bold">Tema:</span> {{theme?.name}}
            </h1>
          </div>
          <div class="col-auto off">
            <button class="btn bg-green text-white me-2" routerLink="/dashboard/paginas/nueva" type="button">
              Nueva página
            </button>

            <button class="btn text-red" type="button" (click)="navigateToBack()">
              Volver
            </button>
          </div>
        </div>
      </article>
    </app-card>
  </section>

  <div class="mt-3" *ngIf="isLoading">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
        <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">Por favor, espere un momento</h2>
      </div>
    </app-card>
  </div>

  <!-- LIST -->
  <section class="mt-3" *ngIf="!isLoading">
    <ul class="row">
      <ng-container *ngFor="let subtheme of theme?.subthemes; index as i">
        <li class="mb-3 col-6">
          <app-card borderRadius="10px" height="100%" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 1px">
            <article card__content>
              <div class="row">
                <div class="col d-flex gap-3">
                  <h2 class="font-18 font-primary--b">
                    <small class="font-12 opacity-50 font-primary--eb" >(ID{{subtheme.id}})</small> {{subtheme.name}}
                  </h2>

                  <span class="d-block text-grey opacity-50">
                    &lt;Sub-Tema&gt;
                  </span>
                </div>

                <div class="col-auto d-flex gap-3">
                  <button
                    type="button"
                    class="btn bg-blue-light text-white"
                    routerLink="/dashboard/paginas/temporadas/{{seasonId}}/capitulos/{{chapterId}}/temas/{{themeId}}/subtemas/{{subtheme.id}}"
                  >
                    Ver
                  </button>

                  <button
                    type="button"
                    class="btn bg-orange-alt text-white"
                    routerLink="/dashboard/paginas/{{subtheme.id}}/editar"
                  >
                    Editar
                  </button>

                  <button
                    type="button"
                    (click)="confirmDelete(subtheme.page_id)"
                    class="btn bg-red text-white"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </article>
          </app-card>
        </li>
      </ng-container>
  
      <article class="mt-3" *ngIf="!theme">
        <app-card borderRadius="10px" padding="80px 120px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
          <section card__content>
            <h2 class="text-center font-24 opacity-50">No has creado ninguna página aun, <span
                routerLink="/dashboard/paginas/nueva" class="text-green cursor-pointer">crea</span>
              una.</h2>
          </section>
        </app-card>
      </article>
    </ul>
  </section>
  </main>
  