import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ActivityService } from 'src/app/services/activity.service';
import { ActivityQuestion } from 'src/app/models/activities/activity.models';

@Component({
  templateUrl: './edit-activity-page.component.html',
  styleUrls: ['./edit-activity-page.component.scss'],
})

export class EditActivityPageComponent implements OnInit {
  activity!: ActivityQuestion
  activityId!: string
  inProcess: boolean = false

  constructor(
    private activeRoute: ActivatedRoute,
    private activityService: ActivityService,
  ) {
    this.activeRoute.queryParams.subscribe((qp) => {
      this.activityId = this.activeRoute.snapshot.params['id']
    });
  }


  ngOnInit(): void {
    this.getActivity()
  }

  activityUpdateHandler() {
    this.getActivity()
  }

  async getActivity() {
    this.inProcess = true
    this.activity = await this.activityService.getActivityById(this.activityId)
    this.inProcess = false
  }
}
