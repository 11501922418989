<div class="navbar-wrapper bg-white h-100">
  <nav class="navbar h-100">

    <a class="navbar__brand" routerLink="/dashboard/inicio" title="inicio">
      <img class="img-fluid" src="/assets/images/icons/icon-brand-optimized.png" alt="">
    </a>

    <ul class="navbar__options">
      <ng-container *ngFor="let option of options">
        <li class="navbar__option" [title]="option.title" [routerLink]="option.link" routerLinkActive="active">
          <a class="icon">
            <img [src]="option.icon" alt="">
          </a>
        </li>
      </ng-container>
    </ul>

    <button class="btn" (click)="logoutHandler()" type="button" title="Cerrar Sesión">
      <img src="/assets/images/icons/icon_logout.png" alt="Cerrar Sesión" class="icon">
    </button>
  </nav>
</div>

<div class="admin-toolbar">
  <a class="btn bg-red" [href]="appUrl">
    <span class="font-16 text-white font-primary--b">
      Ir al App
    </span>
  </a>
</div>
