import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICareerUniversity, CAREER_TYPE_LABEL } from 'src/app/models/career.model';
import { CareersService } from 'src/app/services/careers.service';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-detail-career-university-page',
  templateUrl: './detail-career-university-page.component.html',
  styleUrls: ['./detail-career-university-page.component.scss']
})
export class DetailCareerUniversityPageComponent implements OnInit {
  @ViewChild('content') modal!: ModalComponent
  career!: ICareerUniversity
  CAREER_TYPE_LABEL = CAREER_TYPE_LABEL
  isLoading: boolean = true

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private careerService: CareersService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    const careerId = Number(this.route.snapshot.paramMap.get('id'))

    this.careerService.getCareerById(careerId).subscribe({
      error: (err) => console.error('getCareerById error', err),
      next: (data: ICareerUniversity) => this.career = data,
      complete: () => this.isLoading = false
    })
  }

  confirmDeleteCoupon(id: string | number) {
    this.modalService.open(this.modal, { centered: true })
  }

  deleteCoupon(): void {
    this.careerService.deleteCareer(this.career.id).subscribe({
      error: (err) => console.error('deleteCareer error', err),
      next: (data: ICareerUniversity) => console.log('deleteCareer data', data),
      complete: () => {
        this.dismissModal()
        this.router.navigate(['/dashboard/reportes/carreras-universitarias'])
      }
    })
  }

  dismissModal() {
    this.modalService.dismissAll()
  }
}
