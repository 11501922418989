export const isMultivalued = (type: string) => {
  const isMultivalued = type.includes('SELECT') || type.includes('DISCRETE') || type.includes('CONTINUOUS')
  return isMultivalued
}

export const convertToFormData = (data: { [key: string]: any }) => {
  const formData = new FormData()

  const entries = Object.entries(data)

  entries.forEach(([key, value]) => {
    if (key === 'icon') {
      if (!value) return
    }

    formData.append(key, value)
  })

  return formData
}

// Basic Polyfill groupBy
export const groupBy = function (xs: any[], key: string) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}
