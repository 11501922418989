import { DetailPagePageComponent } from './components/pages/detail-page-page/detail-page-page.component';
import { EditPagePageComponent } from './components/pages/edit-page-page/edit-page-page.component';
import { NewPagePageComponent } from './components/pages/new-page-page/new-page-page.component';
import { PagesPageComponent } from './components/pages/pages-page/pages-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { EditActivityPageComponent } from './components/pages/edit-activity-page/edit-activity-page.component';
import { ActivityPageComponent } from './components/pages/activity-page/activity-page.component';
import { NewActivityPageComponent } from './components/pages/new-activity-page/new-activity-page.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { ActivitiesPageComponent } from './components/pages/activities-page/activities-page.component';
import { SigninPageComponent } from './components/pages/signin-page/signin-page.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { NgModule } from '@angular/core';
import { DashboardLayoutComponent } from './components/layouts/dashboard-layout/dashboard-layout.component';
import { CategoriesParameterPageComponent } from './components/pages/categories-parameter-page/categories-parameter-page.component';
import { DetailCategoryParameterPageComponent } from './components/pages/detail-category-parameter-page/detail-category-parameter-page.component';
import { DetailParameterPageComponent } from './components/pages/detail-parameter-page/detail-parameter-page.component';
import { ParametersPageComponent } from './components/pages/parameters-page/parameters-page.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { UsersComponent } from './components/pages/usuarios/users/users.component';
import { CreateEditUserComponent } from './components/pages/create-edit-user/create-edit-user/create-edit-user.component';
import { CouponsPageComponent } from './components/pages/coupons-page/coupons-page.component';
import { DetailCouponPageComponent } from './components/pages/detail-coupon-page/detail-coupon-page.component';
import { EditCouponPageComponent } from './components/pages/edit-coupon-page/edit-coupon-page.component';
import { NewCouponPageComponent } from './components/pages/new-coupon-page/new-coupon-page.component';
import { CareersUniversityPageComponent } from './components/pages/careers-university-page/careers-university-page.component';
import { NewCareerUniversityPageComponent } from './components/pages/new-career-university-page/new-career-university-page.component';
import { DetailCareerUniversityPageComponent } from './components/pages/detail-career-university-page/detail-career-university-page.component';
import { EditCareerUniversityPageComponent } from './components/pages/edit-career-university-page/edit-career-university-page.component';
import { ReportsPageComponent } from './components/pages/reports-page/reports-page.component';
import { PhrasesPageComponent } from './components/pages/phrases-page/phrases-page.component';
import { EditPhrasePageComponent } from './components/pages/edit-phrase-page/edit-phrase-page.component';
import { DetailPhrasePageComponent } from './components/pages/detail-phrase-page/detail-phrase-page.component';
import { QuestionariesComponent } from './components/pages/questionaries/questionaries.component';
import { PagesSeasonsPageComponent } from './components/pages/pages-seasons-page/pages-seasons-page.component';
import { PagesChaptersPageComponent } from './components/pages/pages-chapters-page/pages-chapters-page.component';
import { PagesThemesPageComponent } from './components/pages/pages-themes-page/pages-themes-page.component';
import { PagesSubthemesPageComponent } from './components/pages/pages-subthemes-page/pages-subthemes-page.component';
import { EditParameterPageComponent } from './components/pages/edit-parameter-page/edit-parameter-page.component';

const routes: Routes = [
  {
    path: "", component: AuthLayoutComponent,
    children: [
      { path: '', redirectTo: 'signin', pathMatch: 'full' },
      { path: "signin", component: SigninPageComponent }
    ]
  },
  {
    path: "dashboard", component: DashboardLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'paginas', pathMatch: 'full' },
      { path: "inicio", component: HomePageComponent },
      {
        path: "actividades", component: ActivitiesPageComponent
      },
      {
        path: 'actividades/nueva', component: NewActivityPageComponent
      },
      {
        path: 'actividades/:id', component: ActivityPageComponent
      },
      {
        path: 'actividades/:id/editar', component: EditActivityPageComponent
      },
      {
        path: 'parametros', component: ParametersPageComponent
      },
      {
        path: 'parametros/:id/editar', component: EditParameterPageComponent
      },
      {
        path: 'parametros/:id', component: DetailParameterPageComponent
      },
      {
        path: 'categorias', component: CategoriesParameterPageComponent
      },
      {
        path: 'categorias/:id', component: DetailCategoryParameterPageComponent
      },
      {
        path: 'cuestionarios', component: QuestionariesComponent
      },
      {
        path: 'paginas', component: PagesPageComponent
      },
      {
        path: 'paginas/temporadas/:seasonId', component: PagesSeasonsPageComponent
      },
      {
        path: 'paginas/temporadas/:seasonId/capitulos/:chapterId', component: PagesChaptersPageComponent
      },
      {
        path: 'paginas/temporadas/:seasonId/capitulos/:chapterId/temas/:themeId', component: PagesThemesPageComponent
      },
      {
        path: 'paginas/temporadas/:seasonId/capitulos/:chapterId/temas/:themeId/subtemas/:subthemeId', component: PagesSubthemesPageComponent
      },
      {
        path: 'paginas/nueva', component: NewPagePageComponent
      },
      {
        path: 'paginas/:id', component: DetailPagePageComponent
      },
      {
        path: 'paginas/:id/editar', component: EditPagePageComponent
      },
      {
        path: 'usuarios', component: UsersComponent
      },
      {
        path: 'usuarios/:id', component: CreateEditUserComponent,
      },
      {
        path: 'usuarios/nuevo-usuario', component: CreateEditUserComponent
      },
      {
        path: 'reportes', component: ReportsPageComponent
      },
      {
        path: 'reportes/carreras-universitarias', component: CareersUniversityPageComponent
      },
      {
        path: 'reportes/carreras-universitarias/nueva', component: NewCareerUniversityPageComponent
      },
      {
        path: 'reportes/carreras-universitarias/:id', component: DetailCareerUniversityPageComponent
      },
      {
        path: 'reportes/carreras-universitarias/:id/editar', component: EditCareerUniversityPageComponent
      },
      {
        path: 'reportes/frases', component: PhrasesPageComponent
      },
      {
        path: 'reportes/frases/:id', component: DetailPhrasePageComponent
      },
      {
        path: 'reportes/frases/:id/editar', component: EditPhrasePageComponent
      },
      {
        path: 'cupones', component: CouponsPageComponent
      },
      {
        path: 'cupones/nuevo', component: NewCouponPageComponent,
      },
      {
        path: 'cupones/:id', component: DetailCouponPageComponent,
      },
      {
        path: 'cupones/:id/editar', component: EditCouponPageComponent,
      },
    ]
  },
  { path: '404', component: NotFoundPageComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
