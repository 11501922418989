import { MetaDataService } from 'src/app/services/metadata.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ParametersService } from 'src/app/services/parameters.service';

@Component({
  templateUrl: './parameters-page.component.html',
  styleUrls: ['./parameters-page.component.scss']
})
export class ParametersPageComponent implements OnInit {
  parameters: { id: number, parameter_name: string, description: string | null }[] = []

  constructor(private parameterService: ParametersService) { }

  ngOnInit(): void {
    this.parameterService.getAllParameters().subscribe({
      error: err => console.error('getAllParameters error', err),
      next: (data: any) => this.parameters = data
    })
  }
}
