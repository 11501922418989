<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading">
  <ng-template #content> <!--Modal Confirm-->
    <div class="modal-body">
      <p class="font-16 font-primary--b text-center">¿Está usted seguro de realizar esta acción?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn">
        <span class="text-red" (click)="dismissModal()">Cancelar</span>
      </button>
      <button type="button" class="btn bg-blue">
        <span class=" text-white" (click)="deleteCoupon()">Continuar</span>
      </button>
    </div>
  </ng-template>

  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content class="row">
        <div class="col d-flex justify-content-start align-items-center">
          <h1 class="font-24 font-primary--eb">{{career.title}}</h1>
          <!-- <span class="font-24 font-primary--eb {{career?.is_available ? 'text-success' : 'text-error'}}">
            {{career?.is_available ? 'Activo' : 'Inactivo'}}
          </span> -->
        </div>

        <div class="d-flex col-4 justify-content-end align-items-center">
          <a
            type="button"
            routerLink="/dashboard/reportes/carreras-universitarias/{{career.id}}/editar"
            class="btn bg-warn text-white align-self-center"
          >
            Editar
          </a>

          <button
            class="btn bg-error text-white ms-2"
            (click)="confirmDeleteCoupon(career.id!)"
          >
            Eliminar
          </button>
        </div>
      </article>
    </app-card>
  </section>

  <div class="career-wrapper">
    <div>
      <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
        <article card__content class="w-100">
          <section>
            <div class="career__icon--wrapper">
              <p class="font-primary--b mb-3 font-16">Icono:</p>

              <img
                class="career-icon"
                [src]="career.icon"
                width="200"
                height="200"
              />
            </div>

            <div class="row mt-4">
              <div class="col-6">
                <p class="font-primary--b mb-3 font-16">Color Primario:</p>

                <div
                  class="w-50px h-30px"
                  [style]="'background-color:' + career.primary_color"
                ></div>
              </div>

              <div class="col-6">
                <p class="font-primary--b mb-3 font-16">Color Secundario:</p>

                <div
                  class="w-50px h-30px"
                  [style]="'background-color:' + career.secundary_color"
                ></div>
              </div>
            </div>
          </section>
        </article>
      </app-card>
    </div>

    <div class="properties-list">
      <div>
        <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
          <article card__content class="w-100">
            <section class="font-16">
              <p class="font-primary--b mb-3">
                Tipo de Carrera: <span>{{CAREER_TYPE_LABEL[career.type]}}</span>
              </p>
            </section>
          </article>
        </app-card>
      </div>

      <div>
        <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
          <article card__content class="w-100">
            <section class="font-16">
              <p class="font-primary--b mb-3">
                Trabajo: <span [outerHTML]="career.job"></span>
              </p>
            </section>
          </article>
        </app-card>
      </div>

      <div>
        <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
          <article card__content class="w-100">
            <section class="font-16">
              <p class="font-primary--b mb-3">
                Descripción: <span [outerHTML]="career.description"></span>
              </p>
            </section>
          </article>
        </app-card>
      </div>
    </div>

    <div class="output-profile">
      <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
        <article card__content class="w-100">
          <section class="font-16">
            <p class="font-primary--b mb-3">
              Salida de Perfil: <span [outerHTML]="career.output_profile"></span>
            </p>
          </section>
        </article>
      </app-card>
    </div>
  </div>
</main>