import { SetActivity, SetActivityCreated } from './../../../store/activities/activity.actions';
import { Observable } from 'rxjs';
import { UpdateType } from './../../../store/type/type.actions';
import { Select, Store } from '@ngxs/store';
import { isMultivalued } from './../../../utils/globals';
import { ActivityService } from 'src/app/services/activity.service';
import { sleep } from './../../../utils/fakers';
import { generateID } from 'src/app/utils/fakers';
import { Component, Input, OnInit, Output } from '@angular/core';
import { Activity, ActivityQuestion, ActivityType } from 'src/app/models/activities/activity.models';
import { TypeState } from 'src/app/store/type/type.state';
import { PageService } from 'src/app/services/page.service';
import { Page } from 'src/app/models/page.models';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-activity-form',
  templateUrl: './activity-form.component.html',
  styleUrls: ['./activity-form.component.scss']
})
export class ActivityFormComponent implements OnInit {
  @Input() editMode: boolean = false
  @Input() activityToEdit?: ActivityQuestion

  @Select(TypeState) type$!: Observable<any>;
  @Output() name!: string
  errors: Array<{ title: string }> = []
  @Output() typeSelected: string | null = '1'
  @Output() includeVideo: boolean = false
  @Output() archived: boolean = false
  @Output() published: boolean = false
  types: Array<ActivityType> = [
    {
      id: generateID(),
      title: 'Texto simple',
      slug: 'text',
      code: 'FREE_TEXT',
    },
    {
      id: generateID(),
      title: 'Video',
      slug: 'video',
      code: 'WATCH',
    },
    {
      id: generateID(),
      title: 'Lista de valores',
      slug: 'list',
      code: 'VALUES_LIST',
    },
    {
      id: generateID(),
      title: 'Selección simple',
      slug: 'simple-selection',
      code: 'SELECT_ANSWER'
    },
    {
      id: generateID(),
      title: 'Selección multiple',
      slug: 'multiple-selection',
      code: 'SELECT_MULTIPLES_ANSWERS'
    },
    {
      id: generateID(),
      title: 'Valoración regular',
      slug: 'regular-valoration',
      code: 'DISCRETE_NUMERIC_ANSWER'
    },
    {
      id: generateID(),
      title: 'Valoración por porcentaje',
      slug: 'percentual-valoration',
      code: 'CONTINUOUS_NUMERIC_ANSWER'
    },
  ]
  @Output() statement: string = ''
  @Output() video?: string
  options: Array<any> = []
  @Output() min: number = 0
  @Output() max: number = 0
  inProcess: boolean = false

  subthemeSelected: string | null = null
  three: any = []

  subthemes: Array<any> = []

  questionaryId: number | null = null
  chapterId: number | null = null
  themeId: number | null = null
  subthemeId: number | null = null
  activityId: number | null = null

  public optionsFroala: Object = {
    placeholderText: 'Ingresa el enunciado de la actividad',
    language: 'es'
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese el enunciado de la pregunta.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [
      // {
      //   name: 'quote',
      //   class: 'quote',
      // },
      // {
      //   name: 'redText',
      //   class: 'redText'
      // },
      // {
      //   name: 'titleText',
      //   class: 'titleText',
      //   tag: 'h1',
      // },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'fontName'
      ],
      [
        'fontSize',
        'insertImage',
        'insertVideo',
        'customClasses',
      ],
    ]
  };

  constructor(private store: Store, private pageService: PageService) {
  }

  getQuestionaries() {
    this.pageService.getQuestionaries().subscribe({
      next: (value) => {
        console.debug(value)
        this.three = value as Page
        console.debug(this.editMode)
        if (this.editMode) {
          const activity = this.findInThree(this.three, 'page_id', +this.activityToEdit?.activity!)
          const subtheme = this.findInThree(this.three, 'page_id', +activity?.parent)
          const theme = this.findInThree(this.three, 'page_id', +subtheme?.parent)
          console.debug(subtheme)
          this.questionaryId = this.three[0].id
          this.chapterId = +theme?.parent
          this.themeId = +subtheme?.parent
          this.subthemeId = activity?.parent
          this.activityId = +this.activityToEdit?.activity!

          console.debug(activity)
          // console.debug()
        }
      },
      error: (error) => { console.debug(error) }
    })
  }

  isSelectable(type: string) {
    let isSelectable = false
    if (type) {
      isSelectable = isMultivalued(type)
    }
    return isSelectable
  }

  ngOnInit() {
    this.getQuestionaries()
    this.type$.subscribe({
      next: (value) => {
        this.typeSelected = value.type
      },
    })
    if (this.editMode) {
      this.name = this.activityToEdit?.name!
      this.statement = this.activityToEdit?.description!
      this.typeSelected = this.activityToEdit!.type
      this.min = this.activityToEdit?.options_min!
      this.max = this.activityToEdit?.options_max!
      this.video = this.activityToEdit?.video_url!
      this.includeVideo = !!this.activityToEdit?.video_url
      this.published = !!this.activityToEdit?.is_published
      // this.subthemeSelected = this.activityToEdit?.activity as string



      this.options = this.activityToEdit?.options!

      this.store.dispatch(new SetActivityCreated(this.activityToEdit!))
      this.store.dispatch(new UpdateType(this.typeSelected))
    }

    // this.getSubthemes()
  }

  /**
   * Reinit form
   */
  resetForm() {
    this.name = ''
    this.statement = ''
    this.includeVideo = false
    this.typeSelected = null
    this.archived = false
    this.min = 0
    this.max = 0
    this.options = []
  }

  /**
   * toggle video input
   */
  toggleIncludeVideo(): void {
    this.includeVideo = !this.includeVideo
    if (!this.includeVideo) {
      this.video = undefined
    }
  }

  /**
   * toggle video input
   */
  togglePublished(): void {
    this.published = !this.published
  }

  /**
   * toggle video input
   */
  toggleArchived(): void {
    this.archived = !this.archived
  }

  /**
   * Enable options for selections | valorations ActivityTypes
   * @returns boolean
   */
  isEnableOptions(): boolean {
    let enableOptions = false
    if (this.typeSelected?.includes('selection') || this.typeSelected?.includes('valoration')) {
      enableOptions = true
    }
    return enableOptions
  }

  /**
   * update type
   * return: void
   */
  updateType(): void {
    this.store.dispatch(new UpdateType(this.typeSelected!))
    this.video = undefined
  }

  /**
   * Filter activities by code
   * @param code
   * @returns
   */
  getActivityByCode(code?: string) {
    let type = this.types.find(activity => activity.code === code)
    return type
  }

  getSubthemes() {
    this.pageService.getPages().subscribe({
      next: (pages: any) => {
        this.subthemes = pages.filter((page: any) => page.is_activity)
        this.getThree()
      },
      error: (error) => { console.debug(error) },
    })
  }

  getThree() {
    this.pageService.getQuestionaries().subscribe({
      next: (data: any) => {
        // this.three = data as Array<any>
        console.debug(data[0])
      },
      error: (err) => { console.error(err) },
    })
  }

  findInThree(three: Array<any>, key: string, value: string | number): Page | undefined {
    let queryResult;
    JSON.stringify(three, (_, nestedValue) => {
      if (nestedValue && nestedValue[key] == value) {
        queryResult = nestedValue;
      }
      return nestedValue;
    });
    return queryResult;
  };

  getChaptersByQuestionary(questionaryId: number): Array<Page> | [] {
    let pages: Array<Page> = []
    if (questionaryId) {
      const query = this.three.find((questionary: any) => questionary.id == questionaryId)
      if (query) {
        pages = query.pages!
      }
    }
    return pages
  }

  getPagesBy(queryKey: string, queryValue: number): Array<Page> | [] {
    let page: Array<Page> = []
    const query = this.findInThree(this.three, queryKey, queryValue)
    if (query) {
      page = query.pages!
    }
    return page
  }

}
