import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaDataService } from 'src/app/services/metadata.service';

@Component({
  selector: 'app-detail-category-parameter-page',
  templateUrl: './detail-category-parameter-page.component.html',
  styleUrls: ['./detail-category-parameter-page.component.scss']
})

export class DetailCategoryParameterPageComponent implements OnInit {
  public categoryId: string = ''
  public categoryParams:any = {};

  constructor(private route: ActivatedRoute, private meta:MetaDataService, private router:Router) {}

  ngOnInit(): void {
    this.categoryId = this.route.snapshot.paramMap.get('id') as string;
    this.getCategoryParams();
  }

  getCategoryParams(){
    this.categoryParams = this.meta.getParamatersByCategory(this.categoryId);
  }

  back(){
    this.router.navigate(['/dashboard/parametros/categorias']);
  }
}
