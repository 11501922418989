import { sleep } from './../../../utils/fakers';
import { Component, OnInit } from '@angular/core';
import { Page } from 'src/app/models/page.models';
import { PageService } from 'src/app/services/page.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { Store } from '@ngxs/store';

@Component({
  templateUrl: './questionaries.component.html',
  styleUrls: ['./questionaries.component.scss']
})
export class QuestionariesComponent implements OnInit {
  questionaries!: Array<any>
  inProcess: boolean = false
  published: boolean = false

  constructor(private pageService: PageService, private store: Store) { }

  ngOnInit(): void {
    this.getQuestionaries()
  }

  getQuestionaries() {
    this.inProcess = true
    this.pageService.getQuestionaries().subscribe({
      next: (value) => {

        this.questionaries = value as any
        this.inProcess = false
      },
      error: (error) => {
        this.inProcess = false
        console.debug(error)
      },
    })

  }

  toggleQuestionary(questionary: any) {
    if (confirm('¿Está seguro de realizar esta accion?')) {
      this.inProcess = true
      questionary.is_published = !questionary.is_published
      this.pageService.updateQuestionary(questionary).subscribe({
        next: (data) => {
          this.getQuestionaries()
          this.inProcess = false
        },
        error: (error) => {
          console.debug(error.error)
          questionary.is_published = !questionary.is_published
          if (error.hasOwnProperty('error') && error.error.length) {
            this.store.dispatch(new OpenModal(`
            Ha ocurrido un error:
            ${error.error[0]}
            `))
          } else {
            this.store.dispatch(new OpenModal(`
            Ha ocurrido un error, pongase en contacto con el soporte`))
          }
          this.inProcess = false
        }
      })
    }
  }

}
