import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParametersService } from 'src/app/services/parameters.service';

@Component({
  selector: 'app-detail-parameter-page',
  templateUrl: './detail-parameter-page.component.html',
  styleUrls: ['./detail-parameter-page.component.scss']
})
export class DetailParameterPageComponent implements OnInit {
  public parameterId!: number
  isLoading: boolean = true
  parameter?: any

  constructor(
    private route: ActivatedRoute,
    private parameterService: ParametersService
  ) { }

  ngOnInit(): void {
    this.parameterId = Number(this.route.snapshot.paramMap.get('id'))

    this.parameterService.getParametersById(this.parameterId).subscribe({
      error: err => console.error('getParametersById error', err),
      next: (data: any) => this.parameter = data,
      complete: () => this.isLoading = false
    })
  }
}
