<div class="preview-component">
  <h2 class="font-primary--b text-blue font-18 text-center">
    Previsualización de la pregunta
  </h2>
  <h3 class="font-primary text-grey font-16 opacity-50 text-center mt-2" *ngIf="!type">
    {{helperText}}
  </h3>
  <div class="w-100" [ngSwitch]="getSlug(type)" *ngIf="type">
    <div class="w-100" *ngSwitchCase="'text'">
      <app-text-activity [statement]="statement" [video]="video">
      </app-text-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'video'">
      <app-video-activity [statement]="statement" [video]="video">
      </app-video-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'simple-selection'">
      <app-multiple-selection-activity [statement]="statement" [video]="video" [isSimple]="true" [min]="min" [max]="1"
        [options]="options"></app-multiple-selection-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'multiple-selection'">
      <app-multiple-selection-activity [statement]="statement" [video]="video" [isSimple]="false" [min]="min"
        [max]="max" [options]="options">
      </app-multiple-selection-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'percentual-valoration'">
      <app-percentual-valoration-activity [statement]="statement" [video]="video" [options]="options">
      </app-percentual-valoration-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'list'">
      <app-list-activity [statement]="statement" [video]="video">
      </app-list-activity>
    </div>
    <div class="w-100" *ngSwitchCase="'regular-valoration'">
      <app-regular-valoration-activity [statement]="statement" [video]="video" [options]="options">
      </app-regular-valoration-activity>
    </div>
    <div *ngSwitchDefault class="text-center mt-3">

    </div>
  </div>
</div>
