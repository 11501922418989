<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading">
  <ng-template #content> <!--Modal Confirm-->
    <div class="modal-body">
      <p class="font-16 font-primary--b text-center">¿Está usted seguro de realizar esta acción?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn">
        <span class="text-red" (click)="dismissModal()">Cancelar</span>
      </button>
      <button type="button" class="btn bg-blue">
        <span class=" text-white" (click)="deleteCoupon()">Continuar</span>
      </button>
    </div>
  </ng-template>

  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content class="row">
        <div class="col d-flex justify-content-start align-items-center">
          <h1 class="font-24 font-primary--eb">{{coupon?.name}}</h1>
          <!-- <span class="font-24 font-primary--eb {{coupon?.is_available ? 'text-success' : 'text-error'}}">
            {{coupon?.is_available ? 'Activo' : 'Inactivo'}}
          </span> -->
        </div>

        <div class="d-flex col-4 justify-content-end align-items-center">
          <a
            type="button"
            routerLink="/dashboard/cupones/{{coupon?.id}}/editar"
            class="btn bg-warn text-white align-self-center"
          >
            Editar
          </a>

          <button
            class="btn bg-error text-white ms-2"
            (click)="confirmDeleteCoupon(coupon?.id!)"
          >
            Eliminar
          </button>
        </div>
      </article>
    </app-card>
  </section>

  <div class="coupon-wrapper">
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
      <article card__content class="w-100">
        <section class="font-16">
          <p class="font-primary--b mb-3">
            Código: <span>{{coupon?.code}}</span>
          </p>
        </section>
      </article>
    </app-card>

    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
      <article card__content class="w-100">
        <section class="font-16">
          <p class="font-primary--b mb-3">
            Descripción: <span>{{coupon?.description}}</span>
          </p>
        </section>
      </article>
    </app-card>

    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
      <article card__content class="w-100">
        <section class="font-16">
          <p class="font-primary--b mb-3">
            Procentaje de Descuento: <span>{{coupon?.discount_percentage}}</span>
          </p>
        </section>
      </article>
    </app-card>

    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
      <article card__content class="w-100">
        <section class="font-16">
          <p class="font-primary--b mb-3">
            Cantidad de Cupones: <span>{{coupon?.available_quantity}}</span>
          </p>
        </section>
      </article>
    </app-card>
  </div>
</main>