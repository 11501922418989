import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ActivityService } from 'src/app/services/activity.service';
import { ActivityState } from 'src/app/store/activities/activity.state';
import { MetaDataService } from 'src/app/services/metadata.service';

import { formatOptionCategoriesAndParameters } from 'src/app/utils/options';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-activity-options',
  templateUrl: './create-activity-options.component.html',
  styleUrls: ['./create-activity-options.component.scss']
})

export class CreateActivityOptionsComponent implements OnInit {
  @Input() editMode: boolean = false
  @Select(ActivityState.activityCreated) activityCreated$!: Observable<any>
  errors: Array<{ title: string }> = []
  isPonderable: boolean = false
  currentActivity: any = null
  categories: any[] = []
  options: any[] = []
  min: number = 0
  max: number = 0
  showMinMax: boolean = false

  constructor(
    private meta: MetaDataService,
    private activitiesService: ActivityService,
    private activeRoute: ActivatedRoute,
    private store: Store
  ) {
    this.categories = this.meta.categories_list
  }

  async ngOnInit() {
    if (this.editMode) {
      const currentActivityId = this.activeRoute.snapshot.params['id']
      const activity = await this.activitiesService.getActivityById(currentActivityId)

      this.currentActivity = activity
      this.options = activity.options
      this.min = activity.options_min
      this.max = activity.options_max
      this.isPonderable = activity.type === 'CONTINUOUS_NUMERIC_ANSWER' ? true : activity.is_ponderable // refactor back: is_ponderable should be true by the type CONTINUOUS_NUMERIC_ANSWER
      this.showMinMax = activity.type === 'SELECT_MULTIPLES_ANSWERS'
      return
    }

    this.activityCreated$.subscribe({
      error: (err) => console.debug('CreateActivityOptions activityCreated Error', err),
      next: (activity: any) => {
        this.currentActivity = activity
        this.showMinMax = activity.type === 'SELECT_MULTIPLES_ANSWERS'
      }
    })
  }

  handleMax(event: any) {
    const value = event.target.value
    const max = this.options.length;

    if (value > max) {
      this.max = max
    }
    if (max > 0 && value < 1) {
      this.max = 1
    }
    if (max === 0 && value < 0) {
      this.max = 0
    }
  }

  handleMin(event: any) {
    const value = event.target.value

    if (value > this.max) {
      this.min = this.max
    }
    if (this.options.length > 0 && value < 1) {
      this.min = 1
    }
    if (this.options.length === 0 && value < 0) {
      this.min = 0
    }
  }

  toggleIsPonderable(): void {
    this.isPonderable = !this.isPonderable
  }

  addOption(): void {
    const activityCreated: any = this.store.selectSnapshot((state: any) => state.Activities.activityCreated);

    const optionBase = {
      question: activityCreated.id,
      body: `Opción por defecto ${this.options.length + 1}`,
      name: `Nombre por defecto ${this.options.length + 1}`
    }

    this.activitiesService.addOption(optionBase).subscribe({
      error: (e) => { console.debug(e) },
      next: (optionAdded: any) => {
        const optionFormated = formatOptionCategoriesAndParameters(optionAdded)

        const newOption = {
          ...optionFormated,
          showCategories: false,
          toggleShowCategories: function () {
            this.showCategories = !this.showCategories
          }
        }

        this.options.push(newOption)
      }
    })
  }

  removeOptionById(id: string): void {
    const confirmRemoveOption = confirm('¿Seguro de remover esta opción?')

    if (confirmRemoveOption) {
      this.options = this.options.filter(option => option.id !== id)
      this.activitiesService.removeOptionById(id).subscribe({
        error: (err) => console.debug('removeOptionById error', err)
      })
    }
  }
}
