<main class="edit-activity-page page">

  <section>

    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center" *ngIf="activity">
          <div class="col">
            <h1 class="font-24 font-primary--eb">Editar actividad &lt;{{activity.name}}&gt; </h1>
          </div>
        </div>
      </article>
    </app-card>
    <div class="mt-3" *ngIf="inProcess">
      <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
        <div card__content>
          <app-loading color="#de377c"></app-loading>
          <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">Por favor, espere un momento</h2>
        </div>
      </app-card>
    </div>
    <article class="mt-3" *ngIf="!inProcess">
      <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
        <article card__content class="" *ngIf="activity">
          <app-activity-stepper [editMode]="true" [activity]="activity" (activityUpdated)="activityUpdateHandler()">
          </app-activity-stepper>
        </article>
      </app-card>
    </article>
  </section>
</main>