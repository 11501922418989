<main class="activities-page page">
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">{{ is_creation ? 'Nuevo' : 'Detalles de' }} usuario</h1>
          </div>
        </div>
      </article>
    </app-card>
  </section>

  <br><br>

  <div class="category-wrapper">
    <div class="user-profile">
      <div>
        <img
          [src]=" user_data.image_profile ? user_data.image_profile : '../../../../../assets/images/icons/icon_avatar_default.png' ">
      </div>
      <div class="user-summary">
        <h5> {{ USER_TYPES_DIC[user_data?.user_type]?.label }} </h5>
        <h3> <b>{{ user_data.first_name }} {{ user_data.last_name }} </b></h3>
        <h6> {{ user_data.email }} </h6>
      </div>

      <div class="user-btn-actions">
        <button
          class="btn bg-green text-white"
          *ngIf="!is_creation"
          (click)="can_edit = true"
        >
          Editar información
        </button>

        <button
          class="btn bg-blue text-white"
          *ngIf="!is_creation"
        >
          Cambiar contraseña
        </button>
      </div>
    </div>

    <br><br>

    <form class="form">
      <div class="row">
        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <label>Email</label>
            <input (keyup)="onEmail()" [disabled]="!can_edit && !is_creation" [(ngModel)]="user_data.email" name="email"
              class="input" type="email" placeholder="usuario@mail.com">
          </div>
          <p class="text-red hint">{{ error_email }}</p>
        </div>
      </div>

      <div class="row" *ngIf="is_creation">
        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <label>* Contraseña</label>

            <input [(ngModel)]="user_data.password" name="password" class="input" type="password"
              (keyup)="onPassword()">
          </div>
          <p class="text-red hint">{{ error_password }}</p>
        </div>

        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <label>* Confirmar contraseña</label>
            <input [(ngModel)]="user_data.confirm_password" name="confirm_password" class="input" type="password"
              (keyup)="onConfirmPassword()">
          </div>

          <p class="text-red hint">{{ error_confirm_password }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <label>Nombre(s)</label>
            <input [disabled]="!can_edit && !is_creation" [(ngModel)]="user_data.first_name" name="first_name"
              class="input" type="text" placeholder="Nombre">
          </div>
        </div>

        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <label>Apellido(s)</label>
            <input [disabled]="!can_edit && !is_creation" [(ngModel)]="user_data.last_name" name="last_name"
              class="input" type="text" placeholder="Apellido">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <label>* Teléfono</label>
            <input [disabled]="!can_edit && !is_creation" [(ngModel)]="user_data.phone" name="phone" class="input"
              type="tel" placeholder="Tel. usuario">
          </div>
        </div>

        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <label>NIF</label>
            <input [disabled]="!can_edit && !is_creation" [(ngModel)]="user_data.tax_id" name="tax_id" class="input"
              type="text" placeholder="NIF del usuario">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <label>Imagen de perfil (URL)</label>
            <textarea rows="3" name="image_profile" [(ngModel)]="user_data.image_profile"
              [disabled]="!can_edit && !is_creation" maxlength="500" style="resize: none"></textarea>
          </div>
        </div>

        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <label>Descripción</label>
            <textarea rows="3" name="description" [(ngModel)]="user_data.description"
              [disabled]="!can_edit && !is_creation" maxlength="500" style="resize: none"></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col col-12 col-md-12 col-sm-12 col-lg-6" *ngIf="is_creation">
          <div class="input-row">
            <label>* Tipo de usuario</label>
            <select title="Tipo de usuario" class="input" [(ngModel)]="user_data.user_type" name="user_type"
              [disabled]="!can_edit && !is_creation">
              <option [value]="null" selected disabled hidden>Selecciona un tipo de usuario</option>
              <option *ngFor="let type of user_types" [value]="type.string_id">{{ type.label }}</option>
            </select>
          </div>
        </div>

        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <label>Suscripción</label>

            <label class="switch ms-3">
              <input [disabled]="!can_edit && !is_creation" [(ngModel)]="user_data.is_subscribed" id="is_suscribed"
                name="is_suscribed" class="input" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <p class="text-red" *ngIf="is_creation || can_edit">
        (*) Campos obligatorios
      </p>

      <br>

      <div class="row">
        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <button [disabled]="(!can_edit && !is_creation)" class="btn btn-secondary text-white"
              (click)="discardChanges()">
              Descartar {{ is_creation ? '' : 'cambios' }}
            </button>
          </div>
        </div>

        <div class="col col-12 col-md-12 col-sm-12 col-lg-6">
          <div class="input-row">
            <button
              [disabled]="
                (!can_edit && !is_creation) ||
                (is_creation && (!user_data.password ||
                !user_data.confirm_password ||
                error_confirm_password !== ''||
                error_password !== '')) ||
                (!user_data.phone ||
                !user_data.user_type ||
                error_email !== '')
              "
              class="btn btn-primary bg-blue-light text-white"
              (click)="is_creation ? createNewUser() : confirmChanges()"
            >
              {{ in_process ? 'Guardando' : 'Guardar' }} {{ is_creation ? 'usuario' : 'cambios' }}
              <span *ngIf="in_process" class="spinner-border spinner-border-sm text-white" role="status"
                aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</main>
<app-modal></app-modal>
