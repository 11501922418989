import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { PageService } from 'src/app/services/page.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { normalizePages, ThemeNormalized } from 'src/app/utils/pages';
@Component({
  selector: 'app-pages-themes-page',
  templateUrl: './pages-themes-page.component.html',
  styleUrls: ['./pages-themes-page.component.scss']
})
export class PagesThemesPageComponent implements OnInit {
  seasonId!: number
  themeId!: number
  chapterId!: number
  theme!: ThemeNormalized | null
  isLoading: boolean = true

  constructor(
    private route: ActivatedRoute,
    private pageService: PageService,
    private store: Store,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.seasonId = Number(this.route.snapshot.paramMap.get('seasonId'))
    this.chapterId = Number(this.route.snapshot.paramMap.get('chapterId'))
    this.themeId = Number(this.route.snapshot.paramMap.get('themeId'))
    this.getPages()
  }

  navigateToBack() {
    this.location.back()
  }

  getPages() {
    this.pageService.getQuestionaries().subscribe({
      next: (data: any) => {
        const seasons = normalizePages(data)
        const seasonFinded = seasons.find(season => season.id === this.seasonId)
        const chapterFinded = seasonFinded?.chapters.find(chapter => chapter.id === this.chapterId)
        const themeFinded = chapterFinded?.themes.find(theme => theme.id === this.themeId)

        this.theme = themeFinded || null
        this.isLoading = false
      },
      error: (e) => {
        this.store.dispatch(new OpenModal('Ha ocurrido un error, pongase en contacto con el administrador.'))
        console.error('Ha ocurrido un error', e)
        this.isLoading = false
      },
    })
  }

  confirmDelete(id: string | number) {
    // TODO IMPROVE UI
    if (confirm('¿Seguro desea realizar esta acción?')) {
      this.deletePage(id)
    }
  }

  deletePage(id: string | number) {
    this.pageService.deletePage(id as string).subscribe({
      complete: () => {
        this.getPages()
        this.store.dispatch(new OpenModal('Página eliminada exitosamente.'))
      },
      error: (e) => {
        console.error(e)
        this.store.dispatch(new OpenModal('Ha ocurrido un error al eliminar.'))
      }
    })
  }
}
