import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  in_progress: boolean = false;
  users: any = [];

  constructor(private userService: UsersService, private router: Router) { }

  ngOnInit(): void {
    this.getAllUsers();
  }

  getAllUsers() {
    this.in_progress = true
    this.userService.getUsers().subscribe((users) => {
      this.in_progress = false
      this.users = [...users];
    })
  }

  viewUserDetails(user_id: any) {
    this.router.navigate(['dashboard/usuarios', user_id])
  }

  togleActiveUser(user: any) {
    user.in_progress = true;
    this.userService.toggleIsActiveUser(user).subscribe((response) => {
      user.is_active = !user.is_active;
      user.in_progress = false;
    }, error => {
      user.in_progress = false;
    })
  }

}
