<main class="page new-page-page">
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">Nueva página</h1>
          </div>
          <div class="col-auto off">
            <button class="btn text-red" routerLink="/dashboard/paginas" type="button">
              Volver
            </button>
          </div>
        </div>
      </article>
    </app-card>
  </section>
  <section class="mt-3">

    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <form action="">
          <div class="row mb-3" *ngIf="errors.length">
            <div class="col">
              <div class="alert alert-danger p-3" role="alert">
                <ul>
                  <li *ngFor="let error of errors">
                    <p class="font-18 font-primary--b">{{error.title}}</p>

                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <app-page-form #newPageForm [three]="three"></app-page-form>
            </div>
            <div class="col-4">
              <app-card height="100%" borderRadius="10px" backgroundColor="#FCFCFC" padding="20px 40px" boxShadow="0">
                <article card__content class="d-flex flex-column align-items-center justify-content-center h-100">
                  <app-preview-three [three]="three" [loading]="inProcess"></app-preview-three>
                </article>
              </app-card>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <button type="button" class="btn bg-green text-white mt-3" (click)="addToThree()">Guardar y
                continuar</button>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn bg-blue text-white mt-3" (click)="addToThree(true)">Guardar</button>
            </div>
          </div>
        </form>
      </article>
    </app-card>
  </section>
</main>