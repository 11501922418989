<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>

<div class="activities-page page" *ngIf="!isLoading && !phrase">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <span class="font-25 font-bold">
        ¡Hubo un error al cargar la frase!
      </span>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading && phrase">
  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <article card__content class="row">
      <h1 class="font-24 font-primary--eb">
        {{phrase.parameter_category_name}} - {{phrase.parameter_name}}
      </h1>
    </article>
  </app-card>

  <app-modal></app-modal>

  <div class="career-wrapper">
    <app-card
      width="100%"
      borderRadius="10px"
      padding="20px 40px"
      class="coupon-wrapper"
      boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
    >
      <div card__content>
        <div class="mt-3">
          <div class="d-flex justify-content-start align-items-center mb-2">
            <div class="me-2">
              <label for="icon" class="text-start w-100 font-bold">
                Icono (*)
              </label>
            </div>

          <div class="d-flex justify-content-start align-items-center">
            <app-toggle-switch [active]="isImageFile" (click)="toggleIsImageFile()"></app-toggle-switch>
              
              <span class="ms-2 font-12 font-primary--b opacity-50">
                {{ isImageFile ? 'Archivo' : 'Enlace' }}
              </span>
            </div>
          </div>
      
          <span
            class="text-start d-block font-14 text-error"
            *ngIf="formErrors?.['icon']"
          >
            {{formErrors?.['icon']?.label}}
          </span>
      
          <input
            id="icon"
            name="icon"
            type="file"
            *ngIf="isImageFile"
            class="icon-input w-100"
            placeholder="Ingrese icono de la carrera"
            (change)="onFileChanged($event)"
          />

          <input
            id="icon"
            name="icon"
            type="text"
            *ngIf="!isImageFile"
            class="input w-100 mt-2"
            placeholder="Ingrese icono de la carrera"
            [(ngModel)]="iconUrl"
          />

          <div class="career__icon--wrapper mt-3" *ngIf="isImageFile">
            <img
              [src]="icon.preview"
              width="200"
              height="200"
              class="career-icon"
              />
          </div>
        </div>

        <form (ngSubmit)="onSubmit()" class="w-100 mt-4">
          <div class="row">
            <div class="col-6">
              <div class="d-flex flex-column">
                <label for="preliminary_first_part" class="text-start w-100 font-bold">Preliminar Primera Parte (*)</label>
    
                <div
                  [froalaEditor]
                  id="preliminary_first_part"
                  class="mt-2"
                  [(froalaModel)]="preliminary_first_part"
                ></div>
              </div>
            </div>

            <div class="col-6">
              <div class="d-flex flex-column">
                <label for="preliminary_second_part" class="text-start w-100 font-bold">Preliminar Segunda Parte (*)</label>
    
                <div
                  [froalaEditor]
                  id="preliminary_second_part"
                  class="mt-2"
                  [(froalaModel)]="preliminary_second_part"
                ></div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-6">
              <div class="d-flex flex-column">
                <label for="final_first_part" class="text-start w-100 font-bold">Final Primera Parte (*)</label>
    
                <div
                  [froalaEditor]
                  id="final_first_part"
                  class="mt-2"
                  [(froalaModel)]="final_first_part"
                ></div>
              </div>
            </div>

            <div class="col-6">
              <div class="d-flex flex-column">
                <label for="final_second_part" class="text-start w-100 font-bold">Final Segunda Parte (*)</label>
    
                <div
                  [froalaEditor]
                  id="final_second_part"
                  class="mt-2"
                  [(froalaModel)]="final_second_part"
                ></div>
              </div>
            </div>
          </div>
          
          <button class="btn font-btn bg-red w-100 font-26 mt-3">
            <app-loading *ngIf="isLoading" color="rgba(255, 255, 255, 1)"></app-loading>
        
            <span class="text-white" *ngIf="!isLoading">
              Actualizar Frase
            </span>
          </button>
        </form>
      </div>
    </app-card>
  </div>
</main>