import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormModel } from 'src/app/models/form.model';
import { ParametersService } from 'src/app/services/parameters.service';
import { handleFormErrors } from 'src/app/utils/handleFormErrors';

@Component({
  selector: 'app-edit-parameter-page',
  templateUrl: './edit-parameter-page.component.html',
  styleUrls: ['./edit-parameter-page.component.scss']
})
export class EditParameterPageComponent implements OnInit {
  parameterForm!: FormGroup
  parameterDescription!: string
  parameterId!: number
  parameter!: { id: number, parameter_name: string, parameter_category_name: string, description: string }
  formErrors: any = {}
  isLoading: boolean = false

  constructor(
    private parameterService: ParametersService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.parameterForm = this.initFrom()
    this.parameterId = Number(this.route.snapshot.paramMap.get('id'))

    this.parameterService.getParametersById(this.parameterId).subscribe({
      error: err => console.error('getParametersById error', err),
      next: (data: any) => this.parameter = data,
      complete: () => {
        this.isLoading = false
      }
    })
  }

  initFrom(): FormGroup {
    return this.fb.group<FormModel<{ description: string }>>({
      description: ['', [Validators.required, Validators.maxLength(300)]],
    })
  }

  onSubmit() {
    this.formErrors = null
    this.isLoading = true

    const { errors, totalErrors } = handleFormErrors(this.parameterForm)

    if (totalErrors) {
      console.error('Edit Parameter Form Error', errors)
      this.formErrors = errors
      this.isLoading = false
      return
    }

    const { description } = this.parameterForm.value

    this.parameterService.updateParameterDescription(this.parameterId, description).subscribe({
      error: (err) => console.error('createCoupon error', err),
      next: (data) => console.log(data),
      complete: () => {
        this.isLoading = false
        this.router.navigate(['/dashboard/parametros'])
      }
    })
  }
}
