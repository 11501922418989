<main class="activities-page page">
  <ng-template #content>
    <div class="modal-body">
      <p class="font-16 font-primary--b text-center">¿Está usted seguro de realizar esta acción?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn">
        <span class="text-red" (click)="dismissModal()">Cancelar</span>
      </button>
      <button type="button" class="btn bg-blue">
        <span class=" text-white" (click)="deleteActivity()">Continuar</span>
      </button>
    </div>
  </ng-template>

  <section>

    <!-- HEADER -->
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">Preguntas</h1>
          </div>
          <div class="col-auto off">
            <button class="btn bg-green text-white" routerLink="/dashboard/actividades/nueva" type="button">Nueva
              Pregunta</button>
          </div>
        </div>
      </article>
    </app-card>

    <!-- LOADER -->
    <div class="mt-3" *ngIf="inProcess">
      <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
        <div card__content>
          <app-loading color="#de377c"></app-loading>
          <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">Por favor, espere un momento</h2>
        </div>
      </app-card>
    </div>

    <!-- ACTIVITIES -->
    <div class="row mt-5" *ngIf="activities && activities.length">
      <div class="col-7">
        <ul class="activities-list">
          <li class="activity-item" [class.selected]="activity.id === selectedActivity?.id"
            *ngFor="let activity of activities | slice: (page-1) * pageSize : page * pageSize">
            <app-card borderRadius="10px" height="100%" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 1px">
              <article card__content>
                <div class="row align-items-center">

                  <div class="btn" (click)="selectingActivity(activity)" class="col d-flex gap-3 cursor-pointer">
                    <small class="font-12 opacity-50">
                      (ID{{activity.id}})
                    </small>
                    <div [innerHTML]="activity.name"></div>
                    <span class="d-block text-grey opacity-50">
                      &lt;{{getActivityByCode(activity.type)?.title}}&gt;
                    </span>
                  </div>
                  <div class="col-auto d-flex gap-3">
                    <button class="btn bg-blue-light  text-white" type="button"
                      routerLink="/dashboard/actividades/{{activity.id}}/editar">Editar</button>

                    <button class="btn bg-red text-white" (click)="confirmDeleteActivity(activity.id!)"
                      type="button">Eliminar</button>
                  </div>
                </div>
                <div class="row mt-2">
                  <span *ngIf="activity.type === 'SELECT_MULTIPLES_ANSWERS'"
                    class="text-grey opacity-50 font-primary font-14">Min: {{activity.options_min}} | Max:
                    {{activity.options_max}}</span>
                  <!-- <p class="mt-2 text-grey opacity-50 font-primary--b font-14">
                    Publicada: {{activity.is_published ? 'Si' : 'No'}}
                  </p> -->
                </div>
              </article>
            </app-card>
          </li>
        </ul>
        <section class="d-flex justify-content-center align-items-center" *ngIf="activities">
          <ngb-pagination [collectionSize]="activities.length" [pageSize]="pageSize" [(page)]="page">
          </ngb-pagination>
        </section>
      </div>
      <div class="col-5">
        <section class="preview-block">
          <!-- Preview -->
          <app-activity-preview class="w-100" [statement]="selectedActivity!.description!"
            [type]="selectedActivity!.type" [video]="selectedActivity!.video_url" [min]="selectedActivity!.options_min!"
            [max]="selectedActivity!.options_max!" [options]="selectedActivity!.options!">
          </app-activity-preview>
        </section>
      </div>
    </div>

    <!-- NOT SAVED ACTIVITIES -->
    <article class="mt-3" *ngIf="activities && !activities.length">
      <app-card borderRadius="10px" padding="80px 120px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
        <section card__content>
          <h2 class="text-center font-24 opacity-50">No has creado ninguna pregunta aun, <span
              routerLink="/dashboard/actividades/nueva" class="text-green cursor-pointer">crea</span>
            una.</h2>
        </section>
      </app-card>
    </article>
  </section>
</main>
