import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICoupon } from 'src/app/models/coupon.model';
import { CouponsService } from 'src/app/services/coupons.service';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-detail-coupon-page',
  templateUrl: './detail-coupon-page.component.html',
  styleUrls: ['./detail-coupon-page.component.scss']
})
export class DetailCouponPageComponent implements OnInit {
  @ViewChild('content') modal!: ModalComponent
  coupon!: ICoupon
  isLoading: boolean = true

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private couponService: CouponsService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    const couponId = this.route.snapshot.paramMap.get('id') as string
    this.couponService.getCouponById(couponId).subscribe({
      error: (err) => console.error('getCouponById error', err),
      next: (data: ICoupon) => this.coupon = data,
      complete: () => this.isLoading = false
    })
  }

  confirmDeleteCoupon(id: string | number) {
    this.modalService.open(this.modal, { centered: true })
  }

  deleteCoupon(): void {
    this.couponService.deleteCoupon(this.coupon.id).subscribe({
      error: (err) => console.error('deleteCoupon error', err),
      next: (data: ICoupon) => console.log('deleteCoupon data', data),
      complete: () => {
        this.dismissModal()
        this.router.navigate(['/dashboard/cupones'])
      }
    })
  }

  dismissModal() {
    this.modalService.dismissAll()
  }
}
