<div class="card-wrapper" [ngStyle]="cardWrapperStyles()" [class.dialog]="dialog">
  <div class="icon-dialog" *ngIf="dialog"></div>
  <div class="card" [ngStyle]="cardStyles()">
    <div class="card__header">
      <ng-content select="[card__header]"></ng-content>
    </div>
    <div class="card__content">
      <ng-content select="[card__content]"></ng-content>
    </div>
    <div class="card__footer"></div>
  </div>
</div>
