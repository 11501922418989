<app-card>
  <app-modal></app-modal>

  <div class="" card__header>
    <h1 class="font-header">Backoffice</h1>
  </div>

  <div class="form-wrapper mt-3" card__content>
    <form (ngSubmit)="onSubmit()" [formGroup]="signInForm" class="form">
      <div>
        <label for="email">Correo Electrónico</label>

        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['email']"
        >
          {{formErrors?.['email']?.label}}
        </span>

        <input
          id="id"
          name="email"
          class="input"
          type="email"
          placeholder="Ingrese su correo electrónico"
          formControlName="email"
        />
      </div>

      <div>
        <label for="passowrd">Contraseña</label>

        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['password']"
        >
          {{formErrors?.['password']?.label}}
        </span>

        <input
          id="password"
          name="password"
          class="input"
          [type]="showPassword ? 'text' :'password'"
          placeholder="Ingrese su contraseña"
          formControlName="password"
        />

        <i
          class="bi bi-eye-fill"
          [class.bi-eye-fill]="!showPassword"
          [class.bi-eye-slash-fill]="showPassword"
          (click)="showPassword = !showPassword"
        ></i>
      </div>

      <div class="actions-wrapper">
        <button [disabled]="isLoading" class="bg-red btn font-btn mt-2" >
          <span class="text-white font-26" *ngIf="!isLoading; else loading">
            Iniciar sesión
          </span>

          <ng-template #loading>
            <app-loading></app-loading>
          </ng-template>
        </button>
      </div>
    </form>

    <div class="group">
      <a routerLink="/recovery" class="text-purple-light link font-regular">¿Has olvidado tu contraseña?</a>
    </div>
  </div>
</app-card>
