import { Injectable } from '@angular/core';

@Injectable()
export class MetaDataService {
  categories_list: any;
  paramters: any;
  constructor() { }

  getCategories() {
    this.categories_list = [
      {
        id: 1,
        stting_id: 'vocational-interest',
        label: 'Intereses Vocacionales'
      },

      {
        id: 2,
        stting_id: 'multiple-intelligence',
        label: 'Inteligencias Multiples'
      },

      {
        id: 3,
        stting_id: 'professional-field',
        label: 'Campos Profesionales'
      },

      {
        id: 4,
        stting_id: 'personality',
        label: 'Personalidades'
      },

      {
        id: 5,
        stting_id: 'AVK',
        label: 'AVK'
      },

      {
        id: 6,
        stting_id: 'learning-style',
        label: 'Estilos de Aprendizaje'
      },

      {
        id: 7,
        stting_id: 'intelligence',
        label: 'Inteligencia'
      },

      {
        id: 8,
        stting_id: 'interest-option',
        label: 'Opciones de Interés'
      },

      {
        id: 9,
        stting_id: 'competence',
        label: 'Competencias'
      }
    ]
    return this.categories_list;
  }

  getAllParameters() {
    this.paramters = [

      {
        'category_id': 1,
        'category_string_id': 'vocational_interests',
        'category_name': 'Intereses Vocacionales',
        'parameters': [
          { id: 'technical', label: 'Técnico' },
          { id: 'investigator', label: 'Investigador' },
          { id: 'entrepreneur', label: 'Emprededor' },
          { id: 'communicator', label: 'Comunicador' },
          { id: 'analyst', label: 'Analista' }
        ]
      },
      {
        'category_id': 2,
        'category_string_id': 'multiple_intelligences',
        'category_name': 'Inteligencias Multiples',
        'parameters': [
          { id: 'linguistic', label: 'Lingüístico' },
          { id: 'mathematical_logician', label: 'Lógico matemático' },
          { id: 'space', label: 'Espacial' },
          { id: 'cinematic_bodily', label: 'Cinemático corporal' },
          { id: 'musical', label: 'Musical' },
          { id: 'intrapersonal', label: 'Intrapersonal' },
          { id: 'interpersonal', label: 'Interpersonal' },
          { id: 'naturalist', label: 'Naturalista' }
        ],
      },
      {
        'category_id': 3,
        'category_string_id': 'professional_fields',
        'category_name': 'Campos Profesionales',
        'parameters': [
          { id: 'manual_technician', label: 'Técnico manual' },
          { id: 'research_scientist', label: 'Científico investigador' },
          { id: 'creative_artistic', label: 'Artístico creativo' },
          { id: 'social_healthcare', label: 'Social-asistencial' },
          { id: 'business_persuasive', label: 'Empresarial persuasivo' },
          { id: 'management', label: 'Administración gestión' },
          { id: 'virtual_digital_streamer', label: 'Virtual/Digital Streamer' }
        ]
      },
      {
        'category_id': 4,
        'category_string_id': 'personalities',
        'category_name': 'Personalidades',
        'parameters': [
          { id: 'perfectionist', label: 'Perfeccionista' },
          { id: 'altruistic', label: 'Altruista' },
          { id: 'active', label: 'Activo' },
          { id: 'affective', label: 'Social asistencial' },
          { id: 'rational', label: 'Racional' },
          { id: 'skeptical', label: 'Escéptico' },
          { id: 'enthusiastic', label: 'Entusiasta' },
          { id: 'leader', label: 'Líder' },
          { id: 'mediator', label: 'Mediador' }
        ]
      },
      {
        'category_id': 5,
        'category_string_id': 'avks',
        'category_name': 'AVK',
        'parameters': [
          { id: 'auditory', label: 'Auditivo' },
          { id: 'visual', label: 'Visual' },
          { id: 'kinesthetic', label: 'Kinestésico' }
        ]
      },
      {
        'category_id': 6,
        'category_string_id': 'learning_styles',
        'category_name': 'Estilos de Aprendizaje',
        'parameters': [
          { id: 'active', label: 'Activo' },
          { id: 'thoughtful', label: 'Reflexivo' },
          { id: 'theoretical', label: 'Teórico' },
          { id: 'pragmatic', label: 'Pragmático' }
        ]
      },
      {
        'category_id': 7,
        'category_string_id': 'intelligences',
        'category_name': 'Inteligencia',
        'parameters': [
          { id: 'self_awareness', label: 'Autoconciencia' },
          { id: 'empathy', label: 'Empatía' },
          { id: 'self_confidence', label: 'Autoconfianza' },
          { id: 'motivation', label: 'Motivación' },
          { id: 'self_control', label: 'Autocontrol' },
          { id: 'social_skills', label: 'Habilidades Sociales' }
        ]
      },
      {
        'category_id': 8,
        'category_string_id': 'interest_options',
        'category_name': 'Opciones de Interés',
        'parameters': [
          { id: 'technical_with_academic_option', label: 'Técnico con opción académica' },
          { id: 'technical_inclination', label: 'Inclinación técnica' },
        ]
      },
      {
        'category_id': 9,
        'category_string_id': 'competencies',
        'category_name': 'Competencías',
        'parameters': [
          { id: 'self_study', label: 'Autoaprendizaje' },
          { id: 'self_critical_capacity', label: 'Capacidad de autocrítica' },
          { id: 'information_search', label: 'Busqueda de información' },
          { id: 'time_organization', label: 'Organización del tiempo' },
          { id: 'social_skills', label: 'Habilidades sociales' },
          { id: 'project_management', label: 'Gestión de proyectos y resolución de problemas' },
          { id: 'adaptability', label: 'Capacidad de Adaptación' },
          { id: 'communication_skills', label: 'Habilidades de comunicación' },
          { id: 'information_treatment', label: 'Tratamiento de la información y competencia dígital' },
          { id: 'informatic_training', label: 'Nivel de formación en APP informaticas' },
          { id: 'it_level_used', label: 'Nivel de uso de las TIC' }
        ]
      }
    ]
    return this.paramters;
  }

  getParamatersByCategory(categ_id: any) {
    let parameters = this.getAllParameters();
    parameters = parameters.find((param: any) => param.category_id == categ_id);
    return parameters;
  }

  getCategoryByParamId(id: string) {
    let selectedCategory: any
    let categories = this.getAllParameters();
    categories.forEach((category: any) => {
      category.parameters.forEach((parameter: any) => {
        if (parameter.id === id) {
          selectedCategory = category
        }
      });
    });
    return selectedCategory
  }

  getParamByParamId(id: string) {
    let selectedParam: any
    let categories = this.getAllParameters();
    categories.forEach((category: any) => {
      category.parameters.forEach((parameter: any) => {
        if (parameter.id === id) {
          selectedParam = parameter
        }
      });
    });
    return selectedParam
  }

  getUserTypes() {
    let user_types = [
      {
        id: 1,
        string_id: 'Particular',
        label: 'Particular'
      },
      {
        id: 2,
        string_id: 'Student',
        label: 'Estudiante'
      },
      {
        id: 3,
        string_id: 'Parent',
        label: 'Representante'
      },
      {
        id: 4,
        string_id: 'Teacher',
        label: 'Profesor'
      },
      {
        id: 5,
        string_id: 'School',
        label: 'Colegio'
      },
      {
        id: 6,
        string_id: 'Back_Office_Admin',
        label: 'Administrador del Back Office'
      },
    ]
    return user_types;
  }

  GET_USER_TYPES_DIC() {
    return {
      'Particular': {
        id: 1,
        label: 'Particular'
      },
      'Student': {
        id: 2,
        label: 'Estudiante'
      },
      'Parent': {
        id: 3,
        label: 'Representante'
      },
      'Teacher': {
        id: 4,
        label: 'Profesor'
      },
      'School': {
        id: 5,
        label: 'Colegio'
      },
      'Back_Office_Admin': {
        id: 6,
        label: 'Administrador del Back Office'
      },
    }
  }
}
