import { TypeState } from './../../../store/type/type.state';
import { generateID } from 'src/app/utils/fakers';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
// Import all Froala Editor plugins.
// import 'froala-editor/js/plugins.pkgd.min.js'; //!ALL PLUGINS
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import { ActivityService } from 'src/app/services/activity.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
// Import a Froala Editor language file.
// import 'froala-editor/js/languages/es.js';

@Component({
  templateUrl: './new-activity-page.component.html',
  styleUrls: ['./new-activity-page.component.scss']
})
export class NewActivityPageComponent implements OnInit {
  @Select(TypeState) type$!: Observable<any>;
  @ViewChild('modalNotification') modalNotification!: ElementRef
  notificationMessage!: string
  inProcess: boolean = false
  selectedType!: string | null
  active: boolean = false

  optionTypes: Array<{ id: string, type: string | null, title: string }> = [
    {
      id: generateID(),
      title: 'Rango',
      type: 'range'
    },
    {
      id: generateID(),
      title: 'Checkbox',
      type: 'checkbox'
    },
  ]

  constructor(private activityService: ActivityService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.type$.subscribe({
      next: (value) => {
        this.selectedType = value.type
      },
    })
  }
  toggleTest() {
    this.active = !this.active
  }

}
