<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>

<div class="activities-page page" *ngIf="!isLoading && !career">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <span class="font-25 font-bold">
        ¡Hubo un error al cargar un cupón!
      </span>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading && career">
  <app-modal></app-modal>

  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <article card__content class="row">
      <h1 class="font-24 font-primary--eb">
        {{career.title}}
      </h1>
    </article>
  </app-card>

  <div class="career-wrapper">
    <app-card
      width="100%"
      borderRadius="10px"
      padding="20px 40px"
      class="coupon-wrapper"
      boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
    >
    <!-- [formGroup]="careerEditForm" -->
      <form
        (ngSubmit)="onSubmit()"
        card__content class="w-100"
      >
        <div class="row">
          <div class="col">
            <label for="icon" class="text-start w-100 font-bold">Icono</label>
        
            <span
              class="text-start d-block font-14 text-error"
              *ngIf="formErrors?.['icon']"
            >
              {{formErrors?.['icon']?.label}}
            </span>
        
            <input
              id="icon"
              name="icon"
              type="file"
              class="icon-input w-100"
              placeholder="Ingrese icono de la carrera"
              (change)="onFileChanged($event)"
            />

            <div class="career__icon--wrapper mt-3">
              <img
                [src]="icon.preview"
                width="200"
                height="200"
                class="career-icon"
              />
            </div>
          </div>

          <div class="col">
            <div>
              <label for="title" class="text-start w-100 font-bold">Titulo de la Carrera</label>

              <span
                class="text-start d-block font-14 text-error"
                *ngIf="formErrors?.['title']"
              >
                {{formErrors?.['title']?.label}}
              </span>
          
              <input
                id="title"
                name="title"
                type="text"
                [value]="career.title"
                class="input w-100"
                placeholder="Ingrese titulo de la carerra"
              />
            </div>

            <div class="mt-3">
              <label for="title" class="text-start w-100 font-bold">Tipo de Carrera</label>

              <span
                class="text-start d-block font-14 text-error"
                *ngIf="formErrors?.['type']"
              >
                {{formErrors?.['type']?.label}}
              </span>
          
              <select
                id="type"
                name="type"
                class="input w-100"
                placeholder="Ingrese tipo de carerra"
              >
                <option [value]="career.type">
                  {{CAREER_TYPE_LABEL[career.type]}}
                </option>

                <option [value]="CAREER_TYPE[career.type === 'TRAINING_CIRCLE' ? 'UNIVERSITY_DEGREE' : 'TRAINING_CIRCLE']">
                  {{CAREER_TYPE_LABEL[career.type === 'TRAINING_CIRCLE' ? 'UNIVERSITY_DEGREE' : 'TRAINING_CIRCLE']}}
                </option>
              </select>
            </div>

            <div class="row mt-3">
              <div class="col-6">
                <label for="primary_color" class="text-start w-100 font-bold">Color Primario</label>
            
                <span
                  class="text-start d-block font-14 text-error"
                  *ngIf="formErrors?.['primary_color']"
                >
                  {{formErrors?.['primary_color']?.label}}
                </span>
    
                <input
                  type="color"
                  [defaultValue]="career.primary_color"
                  style="height: 110px;"
                  class="w-100"
                  /> 
              </div>
    
              <div class="col-6">
                <label for="secundary_color" class="text-start w-100 font-bold">Color Secundario</label>
    
                <span
                  class="text-start d-block font-14 text-error"
                  *ngIf="formErrors?.['secundary_color']"
                >
                  {{formErrors?.['secundary_color']?.label}}
                </span>
            
                <input
                  type="color"
                  [defaultValue]="career.secundary_color"
                  style="height: 110px;"
                  class="w-100"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-6">
            <label for="job" class="text-start w-100 font-bold">Trabajo de la Carrera</label>

            <div
              [froalaEditor]
              [(froalaModel)]="career.job" id="job"
            ></div>
          </div>


          <div class="col-6">
            <label for="description" class="text-start w-100 font-bold">Descripción de la Carrera</label>
            
            <div
              [froalaEditor]
              [(froalaModel)]="career.description" id="description"
            ></div>
          </div>
  
        </div>

        <div class="col-12 mt-3">
          <div class="d-flex flex-column">
            <label for="output_profile" class="font-bold">Salida del Perfil</label>

            <div
              [froalaEditor]
              [(froalaModel)]="career.output_profile" id="output_profile"
            ></div>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div *ngIf="parametersOfCareer.length">
            <span class="font-18 font-primary--b">
              Categorias
            </span>
    
            <ul class="p-0">
              <li
                *ngFor="let category of parametersOfCareer"
                class="w-100 row"
                style="border-radius: 4px; padding: 14px 8px 14px 0px;"
              >
                <div class="text-black mb-3 font-primary--b">
                  {{category.label}}
                </div>

                <!-- Display Parameters -->
                <ul class="d-flex pb-2 ps-4" style="flex-wrap: wrap;">
                  <li class="mt-2 me-3" *ngFor="let parameter of category.parameters;let idx = index; trackBy: getIndex">
                    <div class="d-flex align-items-center justify-content-start">
                      <span>{{parameter.label}}</span>
                    </div>
    
                    <div class="d-flex align-items-center justify-content-start">
                      <input
                        name="{{ parameter.label }}-parameter-{{idx}}"
                        type="number"
                        class="input mt-1"
                        min="0.00"
                        step="0.01"
                        maxlength="6"
                        [(ngModel)]="parameter.value"
                      />
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        
        
        <button class="btn font-btn bg-red w-100 font-26 mt-3">
          <app-loading *ngIf="isLoading" color="rgba(255, 255, 255, 1)"></app-loading>
      
          <span class="text-white" *ngIf="!isLoading">
            Actualizar Carrera Universitaria
          </span>
        </button>
      </form>
    </app-card>
  </div>
</main>