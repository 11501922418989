<!-- APPEARANCE -->
<div class="row justify-content-between mb-3">
  <div class="col-auto">
    <div class="d-flex flex-column">
      <label for="bgImage" class="label mx-2 mb-1 d-flex">
        <span *ngIf="type === 'subtheme';else bgHelper">Miniatura del subtema</span>
        <ng-template #bgHelper>
          <span class="me-3 d-block">
            Imagen de fondo
          </span>
          <app-toggle-switch [active]="isMainImageFile" (click)="toggleFileTypeImage()"></app-toggle-switch>

          <span class="ms-2 d-block font-12 font-primary--b opacity-50" *ngIf="isMainImageFile">
            Archivo
          </span>
          <span class="ms-2 d-block font-12 font-primary--b opacity-50" *ngIf="!isMainImageFile">
            Enlace
          </span>
        </ng-template>
      </label>
      <input *ngIf="isMainImageFile" class="w-100" name="bgImage" type="file" id="bgImage" accept="image/*"
        (change)="_mainImageChange($event)">
      <input class="input w-100" [(ngModel)]="page.image_url" *ngIf="!isMainImageFile" type="text" name="bgImage"
        id="bgImage" placeholder="Enlace de la imagen">
    </div>
  </div>

  <div class="col-auto">
    <div class="preview-img" *ngIf="previewMainImage" [ngStyle]="{backgroundColor: page.background_color}">
      <img [src]="previewMainImage" alt="">
    </div>
    <div class="preview-img" *ngIf="editMode && !previewMainImage" [ngStyle]="{backgroundColor: page.background_color}">
      <img *ngIf="previewMainImage" [src]="previewMainImage" alt="">
      <img *ngIf="!previewMainImage" [src]="page.image" alt="">
    </div>
  </div>

  <div class="col-auto d-flex align-items-center">
    <div class="col-auto">
      <div class="d-flex align-items-center" title="Selecciona un color de fondo para tu página">
        <label for="fontColor" class="label mx-2 mb-1">Color de fondo</label>
        <input class="input cursor-pointer" [(ngModel)]="page.background_color" value="#ffffff" name="fontColor"
          type="color" id="fontColor" placeholder="Selecciona el color de fondo">
      </div>
    </div>
    <div class="col-auto">
      <div class="d-flex align-items-center" title="Selecciona un color de fondo para tu página">
        <label for="backgroundColor" class="label mx-2 mb-1">Color de la fuente</label>
        <input class="input cursor-pointer" [(ngModel)]="page.font_color" value="#ffffff" name="backgroundColor"
          type="color" id="backgroundColor" placeholder="Selecciona el color del texto">
      </div>
    </div>
  </div>
</div>

<!-- GENERAL DATA -->
<div class="row">
  <div class="col">
    <!--  -->
    <div class="d-flex flex-column">
      <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
      <input class="input" [(ngModel)]="page.name" name="name" type="text" id="name"
        placeholder="Ingrese el nombre de la página">
    </div>
  </div>
  <div class="col-auto">
    <div class="d-flex flex-column">
      <label for="type" class="label mx-2 mb-1">Tipo (*)</label>
      <select title="Seleccione el tipo de página que desea crear" [(ngModel)]="type" name="type" id="type">
        <option [value]="null" selected disabled hidden>Seleccione el tipo</option>
        <option [value]="type.slug" *ngFor="let type of types">{{type.body}}</option>
      </select>
    </div>
  </div>
  <div class="col-auto" *ngIf="editMode">
    <div class="d-flex flex-column">
      <label for="order" class="label mx-2 mb-1">Orden <sup class="cursor-pointer"
          title="Define la posición de la página en la lista, '0' indica que la página va de ultima.">📄</sup> </label>
      <input class="input" [(ngModel)]="page.order" name="name" (focusout)="handleMinMax($event)" type="number" min="1"
        max="999" id="order" placeholder="Ingrese el nombre de la página">
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="d-flex flex-column">
      <label for="description" class="label mx-2 mb-1">Descripción</label>
      <!-- <textarea name="description" id="description" [(ngModel)]="page.description"
        placeholder="Ingrese la descripción de la página" id="" cols="30" rows="10"></textarea> -->
      <angular-editor [config]="editorConfig" [(ngModel)]="page.description" name="description" id="description">
      </angular-editor>
    </div>
  </div>
</div>

<!-- VIDEO -->
<div class="row mt-3">
  <div class="col-12">
    <div class="d-flex flex-column">
      <label for="video" class="label mx-2 mb-1">Video</label>
      <input class="input w-100" [(ngModel)]="page.video_url" type="text" name="video" id="video"
        placeholder="Enlace del video">
    </div>
  </div>
</div>

<!-- THEME -->
<div class="row mt-3">
  <div class="col-12">
    <div class="d-flex flex-column">
      <label for="questionary" class="label mx-2 mb-1">Cuestionario contenedor (*)</label>
      <select name="questionary" [(ngModel)]="questionaryId" id="questionary"
        title="Seleccione el cuestionario contenedor">
        <option [value]="null" selected disabled hidden>Seleccione el contenedor</option>
        <option [value]="questionary.id" *ngFor="let questionary of getAllQuestionary(); index as i">
          {{questionary.name}}
        </option>
      </select>
    </div>
  </div>
  <div class="col-12 mt-3" *ngIf="(type === 'theme' || type === 'subtheme' || type === 'activity') && questionaryId ">
    <div class="d-flex flex-column">
      <label for="chapter" class="label mx-2 mb-1">Capítulo contenedor (*)</label>
      <select name="chapter" id="chapter" title="Seleccione al capítulo contenedor" [(ngModel)]="chapterId">
        <option [value]="null" selected disabled hidden>Seleccione el contenedor</option>
        <option [value]="chapter.id" *ngFor="let chapter of three[0].pages ">
          {{chapter.order}}. {{chapter.name}}
        </option>
      </select>
    </div>
  </div>
  <div class="col-12 mt-3" *ngIf="(type === 'subtheme' || type === 'activity') && chapterId">
    <div class="d-flex flex-column">
      <label for="theme" class="label mx-2 mb-1">Tema contenedor (*)</label>
      <select name="theme" id="theme" title="Seleccione al tema contenedor" [(ngModel)]="themeId">
        <option [value]="null" selected disabled hidden>Seleccione el contenedor</option>
        <option [value]="theme.id" *ngFor="let theme of getPagesBy('page_id', +chapterId!)">
          {{theme.order}}. {{theme.name}}
        </option>
      </select>
    </div>
  </div>
  <div class="col-12 mt-3" *ngIf="type === 'activity' && themeId">
    <div class="d-flex flex-column">
      <label for="subtheme" class="label mx-2 mb-1">Subtema contenedor (*)</label>
      <select name="subtheme" id="subtheme" title="Seleccione la actividad contenedora" [(ngModel)]="subthemeId">
        <option [value]="null" selected disabled hidden>Seleccione el contenedor</option>
        <option [value]="subtheme.id" *ngFor="let subtheme of getPagesBy('page_id', +themeId!)">
          {{subtheme.order}}. {{subtheme.name}}
        </option>
      </select>
    </div>
  </div>
</div>

<div class="row mt-3" *ngIf="type === 'chapter'">
  <div class="col-auto">
    <div class="d-flex align-items-center">
      <label for="type" class="label mx-2 mb-1">¿Es el final del modo prueba?</label>
      <div class="col-auto">
        <app-toggle-switch [active]="page.is_preliminar_limit" (click)="togglePreliminarLimit()"></app-toggle-switch>
        <span class="d-inline-block mx-2 font-12 font-primary--b opacity-50" *ngIf="page.is_preliminar_limit">Si</span>
        <span class="d-inline-block mx-2 font-12 font-primary--b opacity-50" *ngIf="!page.is_preliminar_limit">No</span>
      </div>
    </div>
  </div>
</div>
