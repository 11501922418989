import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { SetActivityCreated, ResetActivityCreated } from './../../../store/activities/activity.actions';
import { ActivityQuestion } from 'src/app/models/activities/activity.models';
import { ActivityState } from 'src/app/store/activities/activity.state';
import { ActivityService } from 'src/app/services/activity.service';
import { TypeState } from 'src/app/store/type/type.state';
import { OpenModal } from 'src/app/store/ui/ui.actions';

import { CATEGORY_DIC } from 'src/app/utils/options';
import { isMultivalued } from './../../../utils/globals';

import { CreateActivityOptionsComponent } from './../activities/create-activity-options/create-activity-options.component';
import { ActivityFormComponent } from './../activity-form/activity-form.component';

@Component({
  selector: 'app-activity-stepper',
  templateUrl: './activity-stepper.component.html',
  styleUrls: ['./activity-stepper.component.scss']
})
export class ActivityStepperComponent implements OnInit {
  @ViewChild('activityForm') activityForm!: ActivityFormComponent
  @ViewChild('activityOptionsForm') activityOptionsForm!: CreateActivityOptionsComponent

  @Input() editMode: boolean = false
  @Input() activity?: ActivityQuestion
  @Output() activityUpdated = new EventEmitter<string>();


  @Select(TypeState) type$!: Observable<any>;
  @Select(ActivityState.activityCreated) activityCreated$!: Observable<any>;

  inProcess: boolean = false
  currentStep: number = 0
  stepsList: Array<any> = [
    {
      id: 1,
      title: 'Pregunta',
      //?? att just for step 1
      description: '',
      video_url: '',
      type: '',
      is_archived: false,
      options_min: 0,
      options_max: 0,
      show: true
    },
    {
      id: 2,
      title: 'Opciones',
      options: [],
      show: false
    }
  ]
  typeSelected!: string | null
  errors: Array<{ title: string }> = []

  constructor(
    private activityService: ActivityService,
    private store: Store
  ) { }

  activityUpdateHandler() {
    this.activityUpdated.emit();
  }
  ngOnInit(): void {
    this.store.dispatch(new ResetActivityCreated())
    this.type$.subscribe({
      next: (value) => {
        this.typeSelected = value.type
      },
    })
  }

  isSelectable(type: string) {
    let isSelectable = false
    if (type) {
      isSelectable = isMultivalued(type)
    }
    return isSelectable
  }

  nextStep() {
    const maxSteps = this.stepsList.length - 1
    this.currentStep = this.currentStep === maxSteps ? maxSteps : this.currentStep + 1
  }

  prevStep() {
    const minStep = 0
    this.currentStep = this.currentStep === minStep ? minStep : this.currentStep - 1
  }

  goToStep(step: number) {
    this.currentStep = step
  }

  /**
   * Fields form validations
   * @returns
   */
  isValidForm(activity: ActivityQuestion): boolean {
    const emptyStatement = activity.description === ''
    const emptyName = !activity.name
    const emptyType = activity.type === null
    const emptyVideo = !activity.video_url
    const includeVideo = !!activity.includeVideo
    const emptyActivity = activity.activity === null
    // const selectable = isMultivalued(activity.type!)

    this.errors = []

    let isValidForm = true

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre de la pregunta es requerido.' })
      isValidForm = false
    }
    if (emptyStatement && activity.type !== 'CONTINUOUS_NUMERIC_ANSWER') {
      this.errors.push({ title: 'El enunciado de la pregunta es requerido.' })
      isValidForm = false
    }
    if (emptyType) {
      this.errors.push({ title: 'El tipo de pregunta es requerido.' })
      isValidForm = false
    }
    if (emptyActivity) {
      this.errors.push({ title: 'La actividad contenedora es requerida.' })
      isValidForm = false
    }

    //?? IncludeVideo? then video url is mandatory
    if (includeVideo && emptyVideo) {
      this.errors.push({ title: 'Si la pregunta incluye video recuerde añadirlo.' })
      isValidForm = false
    }

    return isValidForm
  }

  createNewActivity() {

    const min = this.activityForm.typeSelected === 'SELECT_ANSWER' ? 1 : this.activityForm.min
    const max = this.activityForm.typeSelected === 'SELECT_ANSWER' ? 1 : this.activityForm.max

    const newQuestion: ActivityQuestion = {
      description: this.activityForm.statement,
      name: this.activityForm.name,
      video_url: this.activityForm.video || '',
      type: this.activityForm.typeSelected!,
      is_archived: this.activityForm.archived,
      is_ponderable: false,
      is_published: this.activityForm.published,
      options_min: min,
      options_max: max,
      includeVideo: this.activityForm.includeVideo,
      activity: this.activityForm.activityId!
    }

    if (this.isValidForm(newQuestion)) {
      this.inProcess = true

      this.activityService.addActivity(newQuestion).subscribe({
        complete: () => {
          console.debug('[Added Activity]')
          this.inProcess = false
          this.store.dispatch(new OpenModal('Actividad añadida'))
        },
        next: (data: any) => {
          this.store.dispatch(new SetActivityCreated(data))
          if (isMultivalued(this.activityForm.typeSelected!)) {
            this.nextStep()
          } else {
            this.activityForm.resetForm()
            this.store.dispatch(new ResetActivityCreated())
            // this.router.navigate(['/dashboard/actividades'])
          }
        },
        error: (err) => {
          console.error(err)
          this.inProcess = false
          this.store.dispatch(new OpenModal('Ha ocurrido un error al crear la actividad'))
        },
      })
    }
  }

  updateActivity(id: string) {
    this.inProcess = true

    this.activityOptionsForm.showMinMax = this.activityForm.typeSelected === 'SELECT_MULTIPLES_ANSWERS'

    const questionToUpdate: ActivityQuestion = {
      description: this.activityForm.statement,
      name: this.activityForm.name,
      video_url: this.activityForm.video || '',
      type: this.activityForm.typeSelected!,
      is_archived: this.activityForm.archived,
      is_published: this.activityForm.published,
      is_ponderable: this.activityOptionsForm.isPonderable,
      options_min: this.activityOptionsForm.min,
      options_max: this.activityOptionsForm.max,
      includeVideo: this.activityForm.includeVideo,
      activity: Number(this.activityForm.activityId)!,
    }

    this.activityService.updateActivity(id, questionToUpdate).subscribe({
      complete: () => {
        this.inProcess = false
      },
      next: (data: any) => {
        this.activityUpdateHandler()
        // this.store.dispatch(new SetActivityCreated(data))
        if (isMultivalued(this.activityForm.typeSelected!) && this.currentStep === 0) {
          this.nextStep()
        } else {
          this.store.dispatch(new OpenModal(`La actividad '${data.name}' ha sido actualizada.`))
        }
      },
      error: (e) => {
        console.error(e)
        this.inProcess = false
      },
    })

    // Update options
    // Update parameters of categories from a each option
    this.activityOptionsForm.options.forEach(option => {
      const optionToUpdate = {
        question: option.question,
        body: option.body,
        name: option.name,
      }

      this.activityService.updateOption(option.id, optionToUpdate).subscribe({
        error: (err: any) => console.debug('updateOption error', err),
        next: (data: any) => console.log('option updated =>', data)
      })

      option.categories.forEach((category: any) => {
        const parameters: any = {}

        category.parameters.forEach((parameter: any) => parameters[parameter.id] = parameter.value)

        this.activityService.updateParameterByCategoryAndId(
          // @ts-ignore - Manual Check
          CATEGORY_DIC[category.id].id,
          option.parametersId,
          parameters
        ).subscribe({
          error: (err: any) => console.debug('updateParameterByCategoryAndId error', err),
          next: (data: any) => {
            // console.log('updateParameterByCategoryAndId Data', data)
          }
        })
      })
    })

    // this.router.navigate(['/dashboard/actividades'])
  }

  /**
   * Create a new activity
   */
  save() {
    const activityCreated: any = this.store.selectSnapshot((state: any) => state.Activities.activityCreated);

    console.log('activityCreated', activityCreated)

    if (activityCreated === null) {
      this.createNewActivity()
    } else {
      this.updateActivity(activityCreated.id || activityCreated.activity)
    }
  }
}
