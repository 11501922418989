import { generateID } from 'src/app/utils/fakers';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-activity',
  templateUrl: './list-activity.component.html',
  styleUrls: ['./list-activity.component.scss']
})
export class ListActivityComponent implements OnInit {
  @Input() video?: string
  @Input() statement: string = '¿lorem ipsum?'
  values: Array<{ id: string, title: string }> = []
  newValue: string = ''

  constructor() { }

  ngOnInit(): void {
  }
  addItem() {
    const newValue = {
      id: generateID(),
      title: this.newValue
    }
    this.values.push(newValue)
    this.newValue = ''

  }
  removeItem(id: string) {
    const filteredValues = this.values.filter(value => value.id !== id)
    this.values = filteredValues
  }

}
