import { Component, OnInit } from '@angular/core';
import { MetaDataService } from 'src/app/services/metadata.service';
import { generateID } from 'src/app/utils/fakers';

@Component({
  selector: 'app-categories-parameter-page',
  templateUrl: './categories-parameter-page.component.html',
  styleUrls: ['./categories-parameter-page.component.scss']
})

export class CategoriesParameterPageComponent implements OnInit {
  categories:any;

  constructor(private meta:MetaDataService) { }

  ngOnInit(): void {
    this.getCategoriesList();
  }

  getCategoriesList(){
    this.categories = this.meta.getCategories();
  }


  removeItem(id: string) {}

}
