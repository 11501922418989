import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CAREER_TYPE_LABEL, CAREER_TYPE, ICareerToCreate, ICareerUniversity, ICareerToUpdate } from 'src/app/models/career.model';
import { CareersService } from 'src/app/services/careers.service';
import { handleFormErrors } from 'src/app/utils/handleFormErrors';
import { CATEGORY_DIC, formatParameter, normalizeCategories } from 'src/app/utils/options';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-edit-career-university-page',
  templateUrl: './edit-career-university-page.component.html',
  styleUrls: ['./edit-career-university-page.component.scss']
})
export class EditCareerUniversityPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  careerEditForm!: FormGroup
  career!: ICareerUniversity
  CAREER_TYPE_LABEL = CAREER_TYPE_LABEL
  CAREER_TYPE = CAREER_TYPE
  formErrors: any = {}
  isLoading: boolean = true
  icon: { preview: any, file: any } = {
    preview: null,
    file: null,
  }
  outputProfile: string = ''
  description: string = ''
  job: string = ''
  parametersOfCareer: { id: string; label: any; parameters: any[] }[] = []
  min: number = 0
  max: number = 3

  constructor(
    private route: ActivatedRoute,
    private careerService: CareersService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    const careerId = Number(this.route.snapshot.paramMap.get('id'))

    this.careerService.getCareerById(careerId).subscribe({
      error: (err) => console.error('getCareerById error', err),
      next: (data: ICareerUniversity) => {
        this.career = data
        this.icon.preview = data.icon
        this.outputProfile = data.output_profile
        this.description = data.description
        this.job = data.job

        const categoriesFormated = normalizeCategories(data)
        const  parametersFormated = categoriesFormated.flatMap(category => ({
          ...category,
          parameters: formatParameter(category.parameters)
        }))

        this.parametersOfCareer = parametersFormated
      },
      complete: () => {
        this.careerEditForm = this.initFrom()
        this.isLoading = false
        console.log('data cargada')
      }
    })
  }

  initFrom(): FormGroup {
    return this.fb.group({
      title: [ this.career.title || '', [ Validators.required ] ],
      type: [ this.career.type || '', [ Validators.required ] ],
      primary_color: [ this.career.primary_color || '', [ Validators.required ] ],
      secundary_color: [ this.career.secundary_color || '', [ Validators.required ] ],
    })
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0]
      this.icon.file = file;

      const reader = new FileReader()
      reader.onload = () => this.icon.preview = reader.result
      reader.readAsDataURL(file)
    }
  }

  async onSubmit() {
    this.formErrors = null
    this.isLoading = true
    
    const { errors, totalErrors } = handleFormErrors(this.careerEditForm)
    
    if (totalErrors) {
      console.error('Edit Career Form Error', errors)
      this.formErrors = errors
      this.isLoading = false
      return
    }

    if (!this.description ||!this.job || !this.outputProfile) {
      this.modal.open('Hubo un Error', 'Faltan campos requeridos')
      this.isLoading = false
      return
    }

    const url = this.icon.preview
    const fileName = 'icon.jpg'

    const res = await fetch(url)
    const contentType = res.headers.get('content-type')
    const blob = await res.blob()
    const file = new File([blob], fileName, { type: contentType as string })

    let careerToUpdate: ICareerToUpdate = this.careerEditForm.value

    careerToUpdate = {
      ...careerToUpdate,
      icon: this.icon.file || file,
      output_profile: this.outputProfile,
      job: this.job,
      description: this.description,
    }

    this.careerService.updateCareer(this.career.id || null, careerToUpdate).subscribe({
      error: (err) => console.error('updateCoupon error', err),
      next: (data: ICareerUniversity) => this.career = data,
      complete: () => {

        this.parametersOfCareer.forEach((category: any) => {
          const parameters: any = {}
  
          category.parameters.forEach((parameter: any) => parameters[parameter.id] = parameter.value)
  
          this.careerService.updateParameterById(this.career.id, CATEGORY_DIC[category.id].id, parameters).subscribe({
            error: (err: any) => console.debug('updateParameterByCategoryAndId error', err),
            next: (data: any) => console.log('updateParameterById Data', data),
            complete: () => this.router.navigate([`/dashboard/reportes/carreras-universitarias/${this.career.id}`])
          })
        })

        this.isLoading = false
      }
    })
  }

  getIndex(index: number, obj: any) {
    return index
  }
}
