<form action="">
  <div class="row">
    <div class="col">
      <div class="row">
        <!-- CONDITIONALS -->
        <div class="col-12 d-flex justify-content-end">
          <div class="d-flex align-items-center justify-content-end"
            *ngIf="typeSelected !== 'WATCH' && typeSelected !== 'CONTINUOUS_NUMERIC_ANSWER'">
            <label for="include-video" class="label mx-2">Incluir video</label>
            <input class="d-none" type="checkbox" name="includeVideo" (click)="toggleIncludeVideo()" id="include-video"
              [(ngModel)]="includeVideo">
            <app-radio [isActive]="includeVideo" (click)="toggleIncludeVideo()" [color]="'#00CAA8'">
            </app-radio>
          </div>
        </div>

        <!-- Activity type -->
        <div class="col-12 mb-3">
          <div class="d-flex flex-column">
            <label for="activityType" class="label mx-2 mb-1">Tipo (*)</label>
            <select title="Tipo de pregunta" id="activityType" (change)="updateType()" class="input"
              [(ngModel)]="typeSelected" name="activity-type">
              <option [value]="null" selected disabled hidden>Selecciona un tipo</option>
              <option *ngFor="let type of types" [value]="type.code">{{type.title}}</option>
            </select>
          </div>
        </div>

        <!-- ACTIVITY NAME -->
        <div class="col-12 mb-3">
          <div class="d-flex flex-column">
            <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
            <input class="input" [(ngModel)]="name" name="name" type="text" id="name"
              placeholder="Ingrese el nombre de la pregunta">
          </div>
        </div>

        <!-- Activity Statement -->
        <div class="col-12 mb-3" *ngIf="typeSelected !== 'CONTINUOUS_NUMERIC_ANSWER'">
          <div class="d-flex flex-column">
            <label for="activity-statement" class="label mx-2 mb-1">Enunciado (*)</label>
            <angular-editor [config]="editorConfig" [(ngModel)]="statement" name="statement">
            </angular-editor>
          </div>
        </div>
        <!-- <pre>
          {{statement}}
        </pre> -->

        <div class="col-12 mt-3">
          <div class="d-flex flex-column">
            <label for="questionary-container" class="label mx-2 mb-1">Cuestionario</label>
            <select title="Seleccione el cuestionario contenedor" id="questionary-container" class="input"
              [(ngModel)]="questionaryId" name="questionary"
              (change)="chapterId= null; themeId = null; subthemeId = null; activityId = null">
              <option [value]="null" selected disabled hidden>Seleccione el cuestionario</option>
              <option *ngFor="let item of three" [value]="item.id">ID{{item.id}} - {{item.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-3" *ngIf="questionaryId">
          <div class="d-flex flex-column">
            <label for="chapter-container" class="label mx-2 mb-1">Capítulo</label>
            <select title="Seleccione el capítulo contenedor" id="chapter-container" class="input"
              [(ngModel)]="chapterId" name="chapter" (change)="themeId = null; subthemeId = null; activityId = null">
              <option [value]="null" selected disabled hidden>Seleccione el capítulo</option>
              <option *ngFor="let item of getChaptersByQuestionary(questionaryId!)" [value]="item.id">ID{{item.id}} -
                {{item.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-3" *ngIf="chapterId">
          <div class="d-flex flex-column">
            <label for="theme-container" class="label mx-2 mb-1">Tema</label>
            <select title="Seleccione el tema contenedor" id="theme-container" class="input" [(ngModel)]="themeId"
              name="theme" (change)="subthemeId = null; activityId = null">
              <option [value]="null" selected disabled hidden>Seleccione el tema</option>
              <option *ngFor="let item of getPagesBy('page_id', +chapterId!)" [value]="item.id">ID{{item.id}} -
                {{item.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-3" *ngIf="themeId">
          <div class="d-flex flex-column">
            <label for="subtheme-container" class="label mx-2 mb-1">Subtema</label>
            <select title="Seleccione el subtema contenedor" id="subtheme-container" class="input"
              [(ngModel)]="subthemeId" name="subtheme" (change)="activityId = null">
              <option [value]="null" selected disabled hidden>Seleccione el subtema</option>
              <option *ngFor="let item of getPagesBy('page_id', +themeId!)" [value]="item.id">ID{{item.id}} -
                {{item.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 mt-3" *ngIf="subthemeId">
          <div class="d-flex flex-column">
            <label for="activity-container" class="label mx-2 mb-1">Actividad</label>
            <select title="Seleccione el actividad contenedor" id="activity-container" class="input"
              [(ngModel)]="activityId" name="activity">
              <option [value]="null" selected disabled hidden>Seleccione el actividad</option>
              <option *ngFor="let item of getPagesBy('page_id', +subthemeId!)" [value]="item.id">ID{{item.id}} -
                {{item.name}}
              </option>
            </select>
          </div>
        </div>

        <!-- Video Url -->
        <div class="col-12 mt-3" *ngIf="includeVideo || typeSelected === 'WATCH'">
          <div class="d-flex flex-column">
            <label for="video-url" class="label mx-2 mb-1">Video url</label>
            <input class="input" [(ngModel)]="video" name="video" type="text" id="video-url"
              placeholder="Ingresa la url">
          </div>
        </div>
      </div>
    </div>

    <!-- Preview new activity -->
    <div class="col-6 d-flex justify-content-center align-items-center flex-column"
      *ngIf="typeSelected !== 'CONTINUOUS_NUMERIC_ANSWER'">
      <app-activity-preview helperText="Para empezar, indica el tipo de pregunta que deseas crear." class="w-100"
        [statement]="statement" [type]="getActivityByCode(typeSelected!)?.slug!" [video]="video" [options]="options"
        [min]="min" [max]="max">
      </app-activity-preview>
    </div>
  </div>
</form>
