import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICoupon, ICouponToCreate } from 'src/app/models/coupon.model';
import { FormModel } from 'src/app/models/form.model';
import { CouponsService } from 'src/app/services/coupons.service';
import { handleFormErrors } from 'src/app/utils/handleFormErrors';

@Component({
  selector: 'app-new-coupon-page',
  templateUrl: './new-coupon-page.component.html',
  styleUrls: ['./new-coupon-page.component.scss']
})
export class NewCouponPageComponent implements OnInit {
  // isCouponActive: boolean = false
  couponForm!: FormGroup
  @Output() testValue: any = new EventEmitter()
  coupon!: ICoupon | null
  coupon_code = '';
  formErrors: any = {}
  isLoading: boolean = false
  discountPercentage: any = '0'

  constructor(
    private couponService: CouponsService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.couponForm = this.initFrom()
  }

  initFrom(): FormGroup {
    return this.fb.group<FormModel<ICouponToCreate>>({
      name: [ '', [ Validators.required ] ],
      // code: [ '', [ Validators.required, Validators.pattern(/^[A-Za-z0-9_-]*$/g) ] ],
      code: [ '', [ Validators.required, Validators.pattern(/^[A-Za-z0-9_-]*$/) ] ],
      description: [ '', [ Validators.required, Validators.maxLength(50) ] ],
      discount_percentage: [ 0, [ Validators.required ] ],
      available_quantity: [ 0, [ Validators.required, Validators.min(0), Validators.max(999) ] ],
    })
  }

  onSubmit() {
    this.formErrors = null
    this.isLoading = true
    
    const { errors, totalErrors } = handleFormErrors(this.couponForm)

    if (totalErrors) {
      console.error('New Coupon Form Error', errors)
      this.formErrors = errors
      this.isLoading = false
      return
    }

    const { name, code, description, discount_percentage, available_quantity } = this.couponForm.value as ICouponToCreate
    const couponToCreate: ICouponToCreate = { name, code, description, discount_percentage, available_quantity }
    
    this.couponService.createCoupon(couponToCreate).subscribe({
      error: (err) => console.error('createCoupon error', err),
      next: (data) => console.log(data),
      complete: () => {
        this.isLoading = false
        this.router.navigate(['/dashboard/cupones'])
      }
    })
  }
}
