<main class="new-activity-page page">
  <!-- MODAL -->
  <ng-template #modalNotification let-c="close" let-d="dismiss">
    <div class="modal-body" [innerHTML]="notificationMessage">
    </div>
  </ng-template>
  <section>

    <!-- HEADER -->
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col">
            <h1 class="font-24 font-primary--eb">Nueva pregunta</h1>
          </div>
        </div>
      </article>
    </app-card>

    <!-- CONTENT -->
    <article class="mt-3">
      <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
        <article card__content class="">
          <app-activity-stepper></app-activity-stepper>
        </article>
      </app-card>
    </article>
  </section>
</main>
