import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Page } from '../models/page.models';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  getPages() {
    const url = environment.apiUrl + '/questionaries/pages/'
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  getPageById(id: string | number) {
    const url = environment.apiUrl + '/questionaries/pages/' + id + '/'
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  deletePage(id: string) {
    const url = environment.apiUrl + '/questionaries/pages/' + id + '/'
    return this.httpClient.delete(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  getQuestionaries() {
    const url = environment.apiUrl + '/questionaries/'
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  newPage(page: Page) {
    const url = environment.apiUrl + '/questionaries/pages/'
    console.debug(page.image)
    var formData: any = new FormData();
    // MANDATORY
    formData.append('name', page.name);
    formData.append('questionary', page.questionary);
    formData.append('is_activity', page.is_activity);
    formData.append('background_color', page.background_color);
    formData.append('font_color', page.font_color);
    formData.append('is_preliminar_limit', page.is_preliminar_limit);

    // OPTIONAL
    if (page.parent) {
      formData.append('parent', page.parent);
    }
    if (page.video_url) {
      formData.append('video_url', page.video_url);
    }
    if (page.image) {
      formData.append('image', page.image);
    }
    if (page.image_url) {
      formData.append('image_url', page.image_url);
    }
    if (page.description) {
      formData.append('description', page.description);
    }

    return this.httpClient.post(url, formData).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  updatePage(page: Page) {
    const url = environment.apiUrl + '/questionaries/pages/' + page.id + '/'
    var formData: any = new FormData();

    // MANDATORY
    formData.append('name', page.name);
    formData.append('questionary', page.questionary);
    formData.append('is_activity', page.is_activity);
    formData.append('background_color', page.background_color);
    formData.append('font_color', page.font_color);
    formData.append('is_preliminar_limit', page.is_preliminar_limit);
    formData.append('order', page.order);

    // OPTIONAL
    if (page.parent) {
      formData.append('parent', page.parent);
    }

    if (page.video_url) {
      formData.append('video_url', page.video_url);
    }

    if (page.image) {
      formData.append('image', page.image);
    }
    if (page.image_url) {
      formData.append('image_url', page.image_url);
    }

    if (page.description) {
      formData.append('description', page.description);
    }

    return this.httpClient.put(url, formData).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  updateQuestionary(questionary: any) {
    const url = environment.apiUrl + '/questionaries/' + questionary.id + '/switch-is-published/'

    return this.httpClient.put(url, questionary).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

}
