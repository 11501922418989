import { OpenModal } from './../../../store/ui/ui.actions';
import { Store } from '@ngxs/store';
import { PageService } from './../../../services/page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Page } from 'src/app/models/page.models';

@Component({
  templateUrl: './detail-page-page.component.html',
  styleUrls: ['./detail-page-page.component.scss']
})
export class DetailPagePageComponent implements OnInit {
  public pageId!: string
  public page: Page = {
    id: 1,
    name: '',
    description: '',
    background_color: '#ffffff',
    font_color: '#374052',
    image: '',
    video_url: '',
    is_activity: false,
    parent: null,
    previous: null,
    next: null,
    questionary: null,
    order: null,
    is_preliminar_limit: false
  }
  public three: Array<any> = [
    {
      id: 1,
      title: 'Temporada 1',
      type: 'questionary',
      chapters: [
        {
          id: 1,
          title: 'Capitulo 1',
          type: 'chapter',
          themes: [
            {
              id: 1,
              title: 'Tema 1',
              type: 'theme',
              subthemes: [
                {
                  id: 1,
                  title: 'Subtema 1',
                  type: 'subtheme',
                },
                {
                  id: 2,
                  title: 'Subtema 2',
                  type: 'subtheme',
                },
              ]
            }
          ]
        }
      ]
    }
  ]

  constructor(
    private activeRoute: ActivatedRoute,
    private pageService: PageService,
    private store: Store,
    private router: Router) {
    this.pageId = activeRoute.snapshot.params['id']
  }

  ngOnInit(): void {
    this.getPageById()
    this.getQuestionaries()
  }

  getPageById() {
    this.pageService.getPageById(this.pageId).subscribe({
      next: (value) => {
        this.page = value as Page
        if (this.page.parent === null) {
          this.page.type = 'chapter'
        } else {
          // TODO: CHECK IF PAGE IS THEME OR SUBTHEME
          this.page.type = 'theme'
        }
      },
      error: (err) => {
        this.store.dispatch(new OpenModal('Ha ocurrido un error'))
        console.error(err)
      }
    })

  }

  deletePage(id: string | number) {
    this.pageService.deletePage(id as string).subscribe({
      complete: () => {
        this.store.dispatch(new OpenModal('Página eliminada exitosamente.'))
        this.router.navigate(['/dashboard/paginas']);
      },
      error: (e) => console.error(e)
    })
  }

  getQuestionaries() {
    this.pageService.getQuestionaries().subscribe({
      next: (data) => {
        // this.three = data as Array<any>
        // this.three.forEach(questionary => {
        //   questionary.type = 'questionary'
        //   questionary.pages.forEach((chapter: any) => {
        //     chapter.type = 'chapter'
        //     chapter.sub_pages.forEach((theme: any) => {
        //       theme.type = 'theme'
        //       chapter.sub_pages.forEach((subtheme: any) => {
        //         subtheme.type = 'subtheme'
        //       });
        //     });
        //   });
        // })
      },
      error: (e) => { console.error('Ha ocurrido un error', e) },
    })
  }

}
