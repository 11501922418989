import { OpenModal } from './../../../store/ui/ui.actions';
import { Store } from '@ngxs/store';
import { PageService } from './../../../services/page.service';
import { Component, OnInit } from '@angular/core';
import { normalizePages, SeasonNormalized } from 'src/app/utils/pages';

@Component({
  selector: 'app-pages-page',
  templateUrl: './pages-page.component.html',
  styleUrls: ['./pages-page.component.scss']
})
export class PagesPageComponent implements OnInit {
  seasons: SeasonNormalized[] = []
  isLoading: boolean = true

  constructor(private pageService: PageService, private store: Store) { }

  ngOnInit(): void {
    this.getPages()
  }

  getPages() {
    this.pageService.getQuestionaries().subscribe({
      next: (data) => {
        this.isLoading = false
        const seasons = normalizePages(data)
        this.seasons = seasons
      },
      error: (e) => {
        this.store.dispatch(new OpenModal('Ha ocurrido un error, pongase en contacto con el administrador.'))
        console.error('Ha ocurrido un error', e)
        this.isLoading = false
      },
    })
  }

  getNextPage(currentPageIndex: number) {
    let nextPage = null
    if (this.seasons[currentPageIndex + 1]) {
      nextPage = this.seasons[currentPageIndex + 1]
    }
    return nextPage
  }

  confirmDelete(id: string | number) {
    // TODO IMPROVE UI
    if (confirm('¿Seguro desea realizar esta acción?')) {
      this.deletePage(id)
    }
  }

  deletePage(id: string | number) {
    this.pageService.deletePage(id as string).subscribe({
      complete: () => {
        this.getPages()
        this.store.dispatch(new OpenModal('Página eliminada exitosamente.'))
      },
      error: (e) => {
        console.error(e)
        this.store.dispatch(new OpenModal('Ha ocurrido un error al eliminar.'))
      }
    })
  }
}
