import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CAREER_TYPE, CAREER_TYPE_LABEL, ICareerToCreate, ICareerUniversity } from 'src/app/models/career.model';
import { CareersService } from 'src/app/services/careers.service';
import { handleFormErrors } from 'src/app/utils/handleFormErrors';

@Component({
  selector: 'app-new-career-university-page',
  templateUrl: './new-career-university-page.component.html',
  styleUrls: ['./new-career-university-page.component.scss']
})
export class NewCareerUniversityPageComponent implements OnInit {
  createCareerForm!: FormGroup
  career!: ICareerUniversity
  CAREER_TYPE_LABEL = CAREER_TYPE_LABEL
  CAREER_TYPE = CAREER_TYPE
  formErrors: any = {}
  isLoading: boolean = false
  icon: { preview: any, file: any } = {
    preview: null,
    file: null
  }
  outputProfile: string = ''
  description: string = ''
  job: string = ''

  constructor(
    private careerService: CareersService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.createCareerForm = this.initFrom()
  }

  initFrom(): FormGroup {
    return this.fb.group({
      title: [ '', [ Validators.required ] ],
      type: [ '', [ Validators.required ] ],
      // icon: [ null, [ Validators.required ] ],
      primary_color: [ '', [ Validators.required ] ],
      secundary_color: [ '', [ Validators.required ] ],
      // description: [ '', [ Validators.required ] ],
      // job: [ '', [ Validators.required ] ],
      // output_profile: [ '', [ Validators.required ] ]
    })
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0]
      this.icon.file = file

      const reader = new FileReader()
      reader.onload = () => this.icon.preview = reader.result
      reader.readAsDataURL(file)
    }
  }

  onSubmit() {
    this.formErrors = null
    this.isLoading = true
    
    const { errors, totalErrors } = handleFormErrors(this.createCareerForm)
    
    if (totalErrors) {
      console.error('Create Career University Error', errors)
      this.formErrors = errors
      this.isLoading = false
      return
    }

    if (!this.description ||!this.job || !this.outputProfile || !this.icon.file) {
      this.modalService.open('Faltan campos requeridos')
      this.isLoading = false
      return
    }

    let careerToCreate: ICareerToCreate = this.createCareerForm.value

    careerToCreate = {
      ...careerToCreate,
      icon: this.icon.file,
      output_profile: this.outputProfile,
      job: this.job,
      description: this.description
    }
    
    console.log('careerToCreate', careerToCreate)

    this.careerService.createCareer(careerToCreate).subscribe({
      error: (err) => console.error('createCareer error', err),
      next: (data: ICareerUniversity) => this.career = data,
      complete: () => {
        this.isLoading = false
        this.router.navigate([`/dashboard/reportes/carreras-universitarias/${this.career.id}`])
      }
    })
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
}

