<main class="activities-page page">
  <ng-template #content>
    <!--Modal Confirm-->
    <div class="modal-body">
      <p class="font-16 font-primary--b text-center">¿Está usted seguro de realizar esta acción?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn">
        <span class="text-red" (click)="dismissModal()">Cancelar</span>
      </button>
      <button type="button" class="btn bg-blue">
        <span class=" text-white" (click)="deleteCoupon()">Continuar</span>
      </button>
    </div>
  </ng-template>

  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <div class="row align-items-center justify-content-between">
        <div class="col">
          <h1 class="font-24 font-primary--eb">Cupones</h1>
        </div>

        <div class="d-flex col-4 justify-content-end align-items-center">
          <a type="button" routerLink="/dashboard/cupones/nuevo" class="btn bg-success text-white align-self-center">
            Nuevo Cupon
          </a>
        </div>
      </div>
    </div>
  </app-card>

  <div class="mt-3" *ngIf="isLoading">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
        <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">Por favor, espere un momento</h2>
      </div>
    </app-card>
  </div>


  <div class="mt-3" *ngIf="!isLoading && !coupons.length">
    <app-card borderRadius="10px" padding="80px 120px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content class="text-center">
        <span class="text-center font-24 opacity-50">
          No has creado ningun cupon aun,
          <span class="text-green cursor-pointer" routerLink="/dashboard/cupones/nuevo">
            crea
          </span>
          uno.
        </span>
      </div>
    </app-card>
  </div>

  <ul class="coupons__list" *ngIf="!isLoading && coupons.length">
    <li class="coupons__list--item" *ngFor="let coupon of coupons">
      <app-card padding="20px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100%" borderRadius="10px">
        <article card__content class="row">
          <div class="col-6">
            <p class="font-18 font-primary--b">
              {{coupon.name}}
            </p>
          </div>

          <div class="col-6 text-end">
            <a class="btn bg-blue-light text-white" routerLink="/dashboard/cupones/{{coupon.id}}">
              Ver
            </a>

            <button class="btn bg-error text-white ms-2" (click)="confirmDeleteCoupon(coupon.id!)">
              Eliminar
            </button>
          </div>
        </article>
      </app-card>
    </li>
  </ul>
</main>