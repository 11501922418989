<div class="activity-wrapper">
  <div [innerHtml]="statement | safeHtml"></div>
  <hr class="separator">
  <div class="video-container mb-3" *ngIf="video">
    <app-video [options]="{
                fluid: true,
                aspectRatio: '16:9',
                autoplay: false,
                controls: true,
                sources: [{
                  src: video,
                  type: 'video/mp4' }]
                }">
    </app-video>
  </div>
  <ul class="valorations">
    <li class="valoration" *ngFor="let value of options;index as i">
      <label class="valoration__label" for="radio-{{value.id}}">
        {{value.body}}
      </label>
      <input class="valoration__input" type="radio" (click)="updateRadio(i)" name="" id="radio-{{value.id}}"
        [value]="selectedValue" [checked]="value.selected">
      <app-radio (click)="updateRadio(i)" [isActive]="value.selected!" [color]="'#00CAA8'"></app-radio>
    </li>
  </ul>
</div>
<h3 *ngIf="!options!.length" class="font-primary text-grey font-16 opacity-50 text-center">Aun no ha provisto de
  opciones a esta pregunta</h3>
