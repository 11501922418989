import { Option, OptionActivity } from 'src/app/models/activities/option.models';
import { generateID } from 'src/app/utils/fakers';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-percentual-valoration-activity',
  templateUrl: './percentual-valoration-activity.component.html',
  styleUrls: ['./percentual-valoration-activity.component.scss']
})
export class PercentualValorationActivityComponent implements OnInit, AfterViewInit {
  @Input() video?: string
  @Input() statement: string = '¿Default info?'
  @Input() options: Array<OptionActivity> = [
    {
      id: generateID(),
      value: 0,
      disabled: false,
      type: 'checkbox',
      body: 'Default info ',
      question: 0
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    this.setTrackStyles()
  }
  updateRange(event: Event) {
    const target = event.target as HTMLInputElement
    const min = Number(target.min)
    const max = Number(target.max)
    const value = Number(target.value)
    target!.style.background = `linear-gradient(to right, #3AD6BA 0%, #3AD6BA ${(value - min) / (max - min) * 100}%, #E4E6EB ${(value - min) / (max - min) * 100}%, #E4E6EB 100%)`
  }
  setTrackStyles() {
    const sliders: NodeListOf<HTMLInputElement> = document.querySelectorAll(".valoration__input")
    sliders.forEach(slide => {
      const min = 0
      const max = 5
      const value = 0

      slide.style.background = `linear-gradient(to right, red 0%, red ${(value - min) / (max - min) * 100}%, #DEE2E6 ${(value - min) / (max - min) * 100}%, #DEE2E6 100%)`
    });
  }

}
