import { Category } from './../models/category.model';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { Parameter } from '../models/parameter.model';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
  API_URL = environment.apiUrl

  constructor(private httpClient: HttpClient) { }

  getAllParameters() {
    const URL = `${this.API_URL}/reports/parameters/`
    const handleErrors = catchError(error => throwError(() => error))

    return this.httpClient.get(URL).pipe(handleErrors)
  }

  getParametersById(parameterId: number) {
    const URL = `${this.API_URL}/reports/parameters/${parameterId}/`
    const handleErrors = catchError(error => throwError(() => error))

    return this.httpClient.get(URL).pipe(handleErrors)
  }

  addParam(parameter: Parameter) {
    const URL = `${this.API_URL}/parameters/`
    const handleErrors = catchError(error => throwError(() => error))

    return this.httpClient.post(URL, parameter).pipe(handleErrors)
  }

  updateParameterDescription(parameterId: number, parameterDescription: string) {
    const URL = `${this.API_URL}/reports/parameters/${parameterId}/`
    const handleErrors = catchError(error => throwError(() => error))

    return this.httpClient.put(URL, { description: parameterDescription }).pipe(handleErrors)
  }

}
