import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { PhrasesService } from 'src/app/services/phrases.service';
import { groupBy } from 'src/app/utils/globals';

@Component({
  selector: 'app-phrases-page',
  templateUrl: './phrases-page.component.html',
  styleUrls: ['./phrases-page.component.scss']
})
export class PhrasesPageComponent implements OnInit {
  @ViewChild(NgxMasonryComponent) masonry!: NgxMasonryComponent
  public masonryOptions: NgxMasonryOptions = { gutter: 20 }
  phrases: any[] = []
  isLoading: boolean = false
  limit = 3

  constructor( private phrasesService: PhrasesService ) { }

  ngOnInit(): void {
    this.phrasesService.getPhrases().subscribe({
      error: (err) => console.error('getPhrases error', err),
      next: (data: any[]) => {
        const dataGrouped = groupBy(data, 'parameter_category_name')
 	
        this.phrases =  [
          dataGrouped['Intereses vocacionales'],
          dataGrouped['Campos profesionales'],
          dataGrouped['Estilos de aprendizaje'],
          dataGrouped['Inteligencias múltiples'],
          dataGrouped['Inteligencia emocional'],
          dataGrouped['Personalidad'],
        ]
      },
      complete: () => this.isLoading = false
    })
  }
}
