import { ActivityService } from './../../../services/activity.service';
import { ModalComponent } from './../../core/modal/modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
// STORE
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ActivityState } from 'src/app/store/activities/activity.state';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivityQuestion } from 'src/app/models/activities/activity.models';
import { OpenModal } from 'src/app/store/ui/ui.actions';

@Component({
  selector: 'app-activities-page',
  templateUrl: './activities-page.component.html',
  styleUrls: ['./activities-page.component.scss'],
})
export class ActivitiesPageComponent implements OnInit {
  inProcess: boolean = false
  @Select(ActivityState.activities) activities$!: Observable<any>;
  activities!: Array<ActivityQuestion>;
  @ViewChild('content') modal!: ModalComponent;
  page: number = 1;
  pageSize: number = 4;
  activityToDelete!: string | number;
  selectedActivity?: ActivityQuestion;
  types
  activitiesPaginated: any

  constructor(
    private store: Store,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private activitiesService: ActivityService,
  ) {
    this.types = activitiesService.getActivityTypes()
  }

  ngOnInit(): void {
    this.getActivities()
  }

  getActivities() {
    this.inProcess = true
    this.activitiesService.getActivities().subscribe({
      error: (e) => {
        console.debug(e)
        this.store.dispatch(new OpenModal('Ha ocurrido un error, pongase en contacto con el administrador.'))
        this.inProcess = false
      },
      next: (data: any) => {
        this.activities = data
        if (this.activities.length) {
          this.selectedActivity = this.activities[0]
        }
        this.inProcess = false
      },
      complete: () => { console.debug('Finished') },
    })
  }

  getActivityByCode(code?: string) {
    let type = this.types.find(activity => activity.code === code)
    return type
  }

  selectingActivity(activity: ActivityQuestion) {
    this.selectedActivity = activity;
  }

  // MODAL METHODS
  confirmDeleteActivity(id: string | number) {
    this.activityToDelete = id;
    this.modalService.open(this.modal, {
      centered: true,
    });
  }

  deleteActivity(): void {
    const activityId = this.activityToDelete as string
    this.activitiesService.deleteActivity(activityId).subscribe({
      complete: () => {
        this.getActivities()
        this.store.dispatch(new OpenModal('La Pregunta fue eliminada exitosamente.'))
      },
      next: (data) => {
        console.debug(data)
      },
      error: (e) => {
        console.debug(e)
        this.store.dispatch(new OpenModal('Ha ocurrido un error al eliminar la pregunta.'))
      },
    })
    this.dismissModal();
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
}
