import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { MetaDataService } from 'src/app/services/metadata.service';
import { UsersService } from 'src/app/services/users.service';

import { getMismatchPassword } from 'src/app/utils/options';
import { getMismatchEmail } from 'src/app/utils/options';

@Component({
  selector: 'app-create-edit-user',
  templateUrl: './create-edit-user.component.html',
  styleUrls: ['./create-edit-user.component.scss']
})
export class CreateEditUserComponent implements OnInit {

  @ViewChild(ModalComponent) modal!: ModalComponent;

  in_process:boolean = false;
  error_password:string = '';
  error_confirm_password:string = '';
  error_email:string = '';
  can_edit:boolean = false;
  is_creation:boolean = false;
  user_data:any = {};
  original_data:any = {};
  user_types:any = [];
  USER_TYPES_DIC:any = [];
  user_is_subscribed!: boolean
  
  constructor(
    private routeActivated:ActivatedRoute,
    private meta:MetaDataService,
    private router:Router,
    private userService:UsersService) { }

  ngOnInit(): void {
    this.routeActivated.params.subscribe((params:any) => {
      if(params && params.id && Number(params.id)){
        this.getUserDetails(params.id);
        this.is_creation = false;
      }else{
        this.is_creation = true;
      }
    });
    this.user_types = this.meta.getUserTypes();
    this.USER_TYPES_DIC = this.meta.GET_USER_TYPES_DIC();
  }

  
  getUserDetails(user_id:any){
    this.userService.getUserDetails(user_id).subscribe((user_details)=>{
      this.setUserData(user_details);
    })
  }
  
  setUserData(data:any){
    this.user_data = {...data};
    this.original_data = {...data};
  }
  
  createNewUser(){
    this.in_process = true;
    this.userService.createUser(this.user_data).subscribe(()=>{
      this.router.navigate(['/dashboard/usuarios']);
      this.in_process = false;
    }, (e) => {
      this.in_process = false;
      if (e.error.hasOwnProperty('detail')) {
        this.modal.open('Ha ocurrido un error', e.error.detail)
        console.error('Ha ocurrido un error al intentar guardar', e.error.detail)
      } else {
        this.modal.open('Ha ocurrido un error', 'Pongase en contacto con el administrador')
        console.error('Ha ocurrido un error', e.error)
      }
    })
  }

  onPassword(){
    let passwordMatch = getMismatchPassword(this.user_data.password);
    if(passwordMatch){
      if((this.user_data.confirm_password && this.user_data.confirm_password.length > 0) && (this.user_data.password != this.user_data.confirm_password)){
        this.error_password = "Las contraseñas deben coincidir";
      }else{
        this.error_password = "";
      }
    }else{
      this.error_password = 'Longitud entre 8 y 20 caracteres, al menos debe contener una letra mayúscula, una letra minúscula y un número';
    }
  }

  onEmail() {
    let emailMatch = getMismatchEmail(this.user_data.email);
    this.error_email = !emailMatch ? 'Correo electrónico inválido' : '';
  }

  onConfirmPassword(){
    let confirmPasswordMatch = getMismatchPassword(this.user_data.confirm_password);
    if(confirmPasswordMatch){
      if((this.user_data.password && this.user_data.password.length > 0) && (this.user_data.password != this.user_data.confirm_password)){
        this.error_confirm_password = "Las contraseñas deben coincidir";
      }else{
        this.error_confirm_password = "";
      }
    }else{
      this.error_confirm_password = 'Longitud entre 8 y 20 caracteres, al menos debe contener una letra mayúscula, una letra minúscula y un número';
    }
  }

  confirmChanges(){
    this.in_process = true;
    this.userService.editUser(this.user_data).subscribe({
      error: (err) => console.error('edituser error', err),
      next: (data: any) => {
        this.in_process = false;
        this.setUserData(data);
  
        if (this.original_data.is_subscribed !== this.user_data.is_subscribed){
          this.userService.updateFreeSuscriptionUser(this.user_data.id, 1 /* should be premium suscription */).subscribe({
            error: (err) => console.error('updateFreeSuscriptionUser err', err),
          })
        }
  
        this.router.navigate(['/dashboard/usuarios']);
      }
    })
  }

  discardChanges(){
    if(this.is_creation){
      this.router.navigate(['/dashboard/usuarios']);
    }else{
      this.can_edit = false;
      this.user_data = {...this.original_data};
    }
  }

}
