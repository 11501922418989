<main class="activities-page page">
  <br>
  <!-- HEADER -->
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">Usuarios</h1>
          </div>
          <div class="col-auto off">
            <button class="btn bg-green text-white" routerLink="nuevo-usuario" type="button">Nuevo
              Usuario</button>
          </div>
        </div>
      </article>
    </app-card>
    <!-- <div class="row align-items-center">
      <div class="col ">
        <h1 class="font-24 font-primary--eb">Usuarios</h1>
      </div>
      <div class="col-auto off">
        <button class="btn bg-green text-white" type="button" routerLink="nuevo-usuario">
          Crear nuevo usuario +
        </button>
      </div>
    </div> -->
  </section>
  <br>
  <!-- LOADING -->
  <div class="mt-3" *ngIf="in_progress">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
        <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">Por favor, espere un momento</h2>
      </div>
    </app-card>
  </div>
  <!-- USERS LIST -->
  <div class="category-wrapper" *ngIf="!in_progress">
    <table class="table table-striped">
      <!-- HEAD -->
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Usuario</th>
          <th scope="col">Email</th>
          <th scope="col">Nombre(s)</th>
          <th scope="col">Apellido(s)</th>
          <th scope="col">Teléfono</th>
          <th scope="col" class="text-center">NIF</th>
          <th scope="col">Rol</th>
          <th scope="col" class="text-center">Suscrito</th>
          <th scope="col" class="text-center">Acciones</th>
        </tr>
      </thead>
      <!-- BODY -->
      <tbody>
        <tr *ngFor="let user of users">
          <td scope="row"> {{ user.id }} </td>
          <td class="td-img"> <img
              [src]="user.image_profile ? user.image_profile : '../../../../../assets/images/icons/icon_usuario.svg'">
            {{ user.username }} </td>
          <td> {{ user.email }} </td>
          <td> {{ user.first_name }} </td>
          <td> {{ user.last_name }} </td>
          <td> {{ user.phone }} </td>
          <td class="text-center"> {{ user.tax_id }} </td>
          <td> {{ user.user_type }} </td>
          <td class="text-center"> {{ user.is_subscribed ? 'Si' : 'No' }} </td>
          <td class="text-center">
            <button class="btn bg-blue-light text-white" (click)="viewUserDetails(user.id)">Ver / Editar</button> &nbsp;
            <button class="btn bg-blue text-white" (click)="togleActiveUser(user)">
              <span *ngIf="user.in_progress" class="spinner-border spinner-border-sm text-white" role="status"
                aria-hidden="true"></span>
              {{ !user.is_active ? 'Habilitar' : 'Desabilitar' }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</main>