import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MenuOption } from 'src/app/models/menuOption.models';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerComponent implements OnInit {
  appUrl: string
  options: Array<MenuOption> = [
    // {
    //   link: '/dashboard/inicio',
    //   slug: 'home',
    //   icon: '/assets/images/icons/icon_home_alter.svg',
    //   title: 'Inicio',
    // },
    {
      link: '/dashboard/paginas',
      slug: 'pages',
      icon: '/assets/images/icons/icon_home_alter.svg',
      title: 'Páginas',
    },
    {
      link: '/dashboard/cuestionarios',
      slug: 'questionaries',
      icon: '/assets/images/icons/icon_questionaries.svg',
      title: 'Cuestionarios',
    },
    {
      link: '/dashboard/actividades',
      slug: 'activities',
      icon: '/assets/images/icons/icon_list.svg',
      title: 'Actividades',
    },
    {
      link: '/dashboard/parametros',
      slug: 'parameters',
      icon: '/assets/images/icons/icon_instruction.svg',
      title: 'Parametros',
    },
    {
      link: '/dashboard/categorias',
      slug: 'categories',
      icon: '/assets/images/icons/icon_category.svg',
      title: 'Categorías de Parametros',
    },
    {
      link: '/dashboard/usuarios',
      slug: 'users',
      icon: '/assets/images/icons/icon_user.svg',
      title: 'Usuarios',
    },
    {
      link: '/dashboard/cupones',
      slug: 'coupons',
      icon: '/assets/images/icons/icon_coupon.svg',
      title: 'Cupones',
    },
    {
      link: '/dashboard/reportes',
      slug: 'reportes',
      icon: '/assets/images/icons/icon_career_university.svg',
      title: 'Reportes',
    },
  ]

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private router: Router
  ) {
    this.appUrl = environment.appUrl
  }

  ngOnInit(): void {
  }

  logoutHandler() {
    const refreshToken = {
      refresh: this.cookieService.get('bo_refresh')
    }
    this.authService.logout(refreshToken).subscribe({
      complete: () => {
        // this.cookieService.deleteAll()
        this.router.navigate(['/'])
      },
      next: (value) => {
        console.debug('Sesion Cerrada')
        this.cookieService.delete('bo_access', '/')
        this.cookieService.delete('bo_refresh', '/')
      },
      error: (err) => {
        console.error('Ha ocurrido un error, ', err)
      },
    })
  }

}
