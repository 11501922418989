import { ActivityType, ActivityQuestion } from 'src/app/models/activities/activity.models';
import { generateID } from 'src/app/utils/fakers';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { formatActivityWithOptions } from '../utils/options';

@Injectable({
  providedIn: 'root'
})

export class ActivityService {
  API_URL = environment.apiUrl
  activityNormalized: any
  types: Array<ActivityType> = [
    {
      id: '1',
      title: 'Texto simple',
      slug: 'text',
      code: 'FREE_TEXT',

    },
    {
      id: '2',
      title: 'Video',
      slug: 'video',
      code: 'WATCH',
    },
    {
      id: '3',
      title: 'Lista de valores',
      slug: 'list',
      code: 'VALUES_LIST',
    },
    {
      id: '4',
      title: 'Selección simple',
      slug: 'simple-selection',
      code: 'SELECT_ANSWER'
    },
    {
      id: '5',
      title: 'Selección multiple',
      slug: 'multiple-selection',
      code: 'SELECT_MULTIPLES_ANSWERS'
    },
    {
      id: '6',
      title: 'Valoración regular',
      slug: 'regular-valoration',
      code: 'DISCRETE_NUMERIC_ANSWER'
    },
    {
      id: '7',
      title: 'Valoración por porcentaje',
      slug: 'percentual-valoration',
      code: 'CONTINUOUS_NUMERIC_ANSWER'
    },
  ]

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }

  getActivityTypes(): Array<ActivityType> {
    return this.types
  }

  getActivities() {
    const url = environment.apiUrl + '/questionaries/questions/'
    return this.httpClient.get(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  async getActivityById(id: string) {
    const URL = `${this.API_URL}/questionaries/questions/${id}/`
    const headers = { Authorization: 'Bearer ' + this.cookieService.get('bo_access') }

    const res = await fetch(URL, { headers })
    const data = await res.json()
    const activityNormalized = formatActivityWithOptions(data, data.type === 'CONTINUOUS_NUMERIC_ANSWER')

    return activityNormalized
  }

  addActivity(activity: ActivityQuestion) {
    const url = environment.apiUrl + '/questionaries/questions/'
    return this.httpClient.post(url, activity).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }


  deleteActivity(id: string) {
    const url = environment.apiUrl + '/questionaries/questions/' + id + '/'
    return this.httpClient.delete(url).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
  archiveActivity(id: string, newStatus: { is_archived: boolean, activity: number }) {
    const url = environment.apiUrl + '/questionaries/questions/' + id + '/'
    return this.httpClient.put(url, newStatus).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  updateActivity(id: string | number, activity: ActivityQuestion | { activity: string | number }) {
    const url = environment.apiUrl + '/questionaries/questions/' + id + '/'
    return this.httpClient.put(url, activity).pipe(
      catchError(error => {
        return throwError(() => error)
      })
    )
  }

  // Options
  addOption(option: any /* OptionActivity */) {
    const URL = `${this.API_URL}/questionaries/options/`

    return this.httpClient.post(URL, option).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  updateOption(id: string, option: { question: number, body: string, name: string }) {
    const URL = `${this.API_URL}/questionaries/options/${id}/`

    return this.httpClient.put(URL, option).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  removeOptionById(id: string) {
    const URL = `${this.API_URL}/questionaries/options/${id}/`
    return this.httpClient.delete(URL).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  // Parameters
  async getParameters(categories: any) {
    const urlParameters = categories.map((category: any) => `${this.API_URL}/parameters/${category.id}/`)
    const headers = { Authorization: 'Bearer ' + this.cookieService.get('bo_access') }

    const allParameters = await Promise.all(urlParameters.map(async (url: string) => {
      const res = await fetch(url, { headers })
      const data = await res.json()
      return data
    }))

    return allParameters
  }

  updateParameterByCategoryAndId(category: string, parameterId: number, parameterBody: { [key: string]: number }) {
    const url = `${this.API_URL}/parameters/${category}/${parameterId}/`

    return this.httpClient.put(url, parameterBody).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
}
