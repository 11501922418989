<main class="page cuestionaries-page">
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">Cuestionarios</h1>
          </div>
        </div>
      </article>
    </app-card>
  </section>
  <div class="mt-3" *ngIf="inProcess">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
        <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">Por favor, espere un momento</h2>
      </div>
    </app-card>
  </div>
  <section class="mt-3" *ngIf="!inProcess">
    <ul class="row">
      <ng-container *ngFor="let questionary of questionaries; index as i">
        <li class="mb-3 col-12">
          <app-card borderRadius="10px" height="100%" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 1px">
            <article card__content>
              <div class="row">
                <div class="col d-flex gap-3">
                  <h2 class="font-18 font-primary--b">
                    <small class="font-12 opacity-50 font-primary--eb">(ID{{questionary.id}})</small>
                    {{questionary.name}}
                  </h2>
                  <span *ngIf="questionary.type === 'chapter'" class="d-block text-grey opacity-50">
                    &lt;Capítulo&gt;
                  </span>
                </div>
                <div class="col-auto d-flex gap-3">
                  <div class="cursor-pointer" (click)="toggleQuestionary(questionary)">

                    <span *ngIf="!questionary.is_published">
                      No Publicado
                    </span>
                  </div>
                  <app-toggle-switch color="red" [active]="questionary.is_published"
                    (click)="toggleQuestionary(questionary)">
                  </app-toggle-switch>
                  <span *ngIf="questionary.is_published">
                    Publicado
                  </span>
                </div>
              </div>
            </article>
          </app-card>
        </li>
      </ng-container>
    </ul>
  </section>
</main>