import { HttpErrorInterceptorInterceptor } from './interceptors/http-error-interceptor.interceptor';
import { PreviewThreeComponent } from './components/core/preview-three/preview-three.component';
import { UiState } from './store/ui/ui.state';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardLayoutComponent } from './components/layouts/dashboard-layout/dashboard-layout.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { SigninPageComponent } from './components/pages/signin-page/signin-page.component';
import { ActivitiesPageComponent } from './components/pages/activities-page/activities-page.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { DrawerComponent } from './components/core/drawer/drawer.component';
import { LoadingComponent } from './components/core/loading/loading.component';
import { ModalComponent } from './components/core/modal/modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from './components/core/card/card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RecaptchaFormsModule, RecaptchaV3Module, RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { TextActivityComponent } from './components/core/activities/text-activity/text-activity.component';
import { VideoActivityComponent } from './components/core/activities/video-activity/video-activity.component';
import { SimpleSelectionActivityComponent } from './components/core/activities/simple-selection-activity/simple-selection-activity.component';
import { MultipleSelectionActivityComponent } from './components/core/activities/multiple-selection-activity/multiple-selection-activity.component';
import { ListActivityComponent } from './components/core/activities/list-activity/list-activity.component';
import { RegularValorationActivityComponent } from './components/core/activities/regular-valoration-activity/regular-valoration-activity.component';
import { PercentualValorationActivityComponent } from './components/core/activities/percentual-valoration-activity/percentual-valoration-activity.component';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { ActivityState } from './store/activities/activity.state';
import { VideoComponent } from './components/core/video/video.component';
import { RadioComponent } from './components/core/radio/radio.component';
import { NewActivityPageComponent } from './components/pages/new-activity-page/new-activity-page.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ActivityPageComponent } from './components/pages/activity-page/activity-page.component';
import { EditActivityPageComponent } from './components/pages/edit-activity-page/edit-activity-page.component';
import { ErrorLayoutComponent } from './components/layouts/error-layout/error-layout.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { NewParameterPageComponent } from './components/pages/new-parameter-page/new-parameter-page.component';
import { CategoriesParameterPageComponent } from './components/pages/categories-parameter-page/categories-parameter-page.component';
import { DetailCategoryParameterPageComponent } from './components/pages/detail-category-parameter-page/detail-category-parameter-page.component';
import { ParametersPageComponent } from './components/pages/parameters-page/parameters-page.component';
import { ActivityPreviewComponent } from './components/core/activity-preview/activity-preview.component';
import { DetailParameterPageComponent } from './components/pages/detail-parameter-page/detail-parameter-page.component';
import { ActivityStepperComponent } from './components/core/activity-stepper/activity-stepper.component';
import { CreateActivityOptionsComponent } from './components/core/activities/create-activity-options/create-activity-options.component';
import { ActivityFormComponent } from './components/core/activity-form/activity-form.component';
import { ToggleSwitchComponent } from './components/core/toggle-switch/toggle-switch.component';
import { TypeState } from './store/type/type.state';
import { MetaDataService } from './services/metadata.service';
import { GlobalModalComponent } from './components/core/global-modal/global-modal.component';
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';
import { PagesPageComponent } from './components/pages/pages-page/pages-page.component';
import { NewPagePageComponent } from './components/pages/new-page-page/new-page-page.component';
import { PageFormComponent } from './components/core/page-form/page-form.component';
import { EditPagePageComponent } from './components/pages/edit-page-page/edit-page-page.component';
import { DetailPagePageComponent } from './components/pages/detail-page-page/detail-page-page.component';
import { UsersComponent } from './components/pages/usuarios/users/users.component';
import { UsersService } from './services/users.service';
import { CreateEditUserComponent } from './components/pages/create-edit-user/create-edit-user/create-edit-user.component';
import { NextPagePipe } from './pipes/next-page.pipe';
import { PrevPagePipe } from './pipes/prev-page.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { CareersUniversityPageComponent } from './components/pages/careers-university-page/careers-university-page.component';
import { DetailCareerUniversityPageComponent } from './components/pages/detail-career-university-page/detail-career-university-page.component';
import { EditCareerUniversityPageComponent } from './components/pages/edit-career-university-page/edit-career-university-page.component';
import { NewCareerUniversityPageComponent } from './components/pages/new-career-university-page/new-career-university-page.component';
import { CouponsPageComponent } from './components/pages/coupons-page/coupons-page.component';
import { DetailCouponPageComponent } from './components/pages/detail-coupon-page/detail-coupon-page.component';
import { EditCouponPageComponent } from './components/pages/edit-coupon-page/edit-coupon-page.component';
import { NewCouponPageComponent } from './components/pages/new-coupon-page/new-coupon-page.component';
import { PercentageDirective } from './directives/percentage.directive';
import { ReportsPageComponent } from './components/pages/reports-page/reports-page.component';
import { PhrasesPageComponent } from './components/pages/phrases-page/phrases-page.component';
import { EditPhrasePageComponent } from './components/pages/edit-phrase-page/edit-phrase-page.component';
import { DetailPhrasePageComponent } from './components/pages/detail-phrase-page/detail-phrase-page.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { QuestionariesComponent } from './components/pages/questionaries/questionaries.component';
import { PagesSeasonsPageComponent } from './components/pages/pages-seasons-page/pages-seasons-page.component';
import { PagesChaptersPageComponent } from './components/pages/pages-chapters-page/pages-chapters-page.component';
import { PagesThemesPageComponent } from './components/pages/pages-themes-page/pages-themes-page.component';
import { PagesSubthemesPageComponent } from './components/pages/pages-subthemes-page/pages-subthemes-page.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { EditParameterPageComponent } from './components/pages/edit-parameter-page/edit-parameter-page.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    PercentageDirective,
    DashboardLayoutComponent,
    SigninPageComponent,
    ActivitiesPageComponent,
    HomePageComponent,
    DrawerComponent,
    LoadingComponent,
    ModalComponent,
    CardComponent,
    TextActivityComponent,
    VideoActivityComponent,
    SimpleSelectionActivityComponent,
    MultipleSelectionActivityComponent,
    PercentualValorationActivityComponent,
    ListActivityComponent,
    RegularValorationActivityComponent,
    VideoComponent,
    RadioComponent,
    NewActivityPageComponent,
    ActivityPageComponent,
    EditActivityPageComponent,
    ErrorLayoutComponent,
    NotFoundPageComponent,
    NewParameterPageComponent,
    CategoriesParameterPageComponent,
    DetailCategoryParameterPageComponent,
    ParametersPageComponent,
    ActivityPreviewComponent,
    DetailParameterPageComponent,
    ActivityStepperComponent,
    CreateActivityOptionsComponent,
    ActivityFormComponent,
    ToggleSwitchComponent,
    GlobalModalComponent,
    PagesPageComponent,
    NewPagePageComponent,
    PageFormComponent,
    EditPagePageComponent,
    DetailPagePageComponent,
    UsersComponent,
    CreateEditUserComponent,
    PreviewThreeComponent,
    NextPagePipe,
    PrevPagePipe,
    OrderByPipe,
    CareersUniversityPageComponent,
    DetailCareerUniversityPageComponent,
    EditCareerUniversityPageComponent,
    NewCareerUniversityPageComponent,
    CouponsPageComponent,
    DetailCouponPageComponent,
    EditCouponPageComponent,
    NewCouponPageComponent,
    ReportsPageComponent,
    PhrasesPageComponent,
    EditPhrasePageComponent,
    DetailPhrasePageComponent,
    QuestionariesComponent,
    PagesSeasonsPageComponent,
    PagesChaptersPageComponent,
    PagesThemesPageComponent,
    PagesSubthemesPageComponent,
    SafeHtmlPipe,
    EditParameterPageComponent,
  ],
  imports: [
    NgxMasonryModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    // Store
    NgxsModule.forRoot([ActivityState, TypeState, UiState], {
      developmentMode: !environment.production,
    }),
    AngularEditorModule

  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "es",
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LcY1xsgAAAAAGgTMBARSx_KrF2vSfi2K0JsZe25"
    },
    MetaDataService,
    UsersService,
    // NgxMasonryModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
