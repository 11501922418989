<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading">
  <ng-template #content>
    <div class="modal-body">
      <p class="font-16 font-primary--b text-center">¿Está usted seguro de realizar esta acción?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn">
        <span class="text-red" (click)="dismissModal()">Ok</span>
      </button>
    </div>
  </ng-template>

  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <article card__content class="row">
      <h1 class="font-24 font-primary--eb">
        Nueva Carrera Universitaria
      </h1>
    </article>
  </app-card>

  <div class="career-wrapper">
    <app-card
      width="100%"
      borderRadius="10px"
      padding="20px 40px"
      class="coupon-wrapper"
      boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
    >
      <form
        (ngSubmit)="onSubmit()"
        [formGroup]="createCareerForm"
        card__content class="w-100"
      >
        <div>
          <label for="title" class="text-start w-100 font-bold">Titulo de la Carrera</label>

          <span
            class="text-start d-block font-14 text-error"
            *ngIf="formErrors?.['title']"
          >
            {{formErrors?.['title']?.label}}
          </span>
      
          <input
            id="title"
            name="title"
            type="text"
            class="input w-100"
            placeholder="Ingrese titulo de la carerra universitaria"
            formControlName="title"
          />
        </div>

        <div class="mt-3">
          <label for="title" class="text-start w-100 font-bold">Tipo de Carrera</label>

          <span
            class="text-start d-block font-14 text-error"
            *ngIf="formErrors?.['type']"
          >
            {{formErrors?.['type']?.label}}
          </span>

          <select
            id="type"
            name="type"
            class="w-100"
            formControlName="type"
          >
            <option value="" selected disabled hidden>Elije un tipo</option>

            <option [value]="CAREER_TYPE['TRAINING_CIRCLE']">
              {{CAREER_TYPE_LABEL[CAREER_TYPE['TRAINING_CIRCLE']]}}
            </option>

            <option [value]="CAREER_TYPE['UNIVERSITY_DEGREE']">
              {{CAREER_TYPE_LABEL[CAREER_TYPE['UNIVERSITY_DEGREE']]}}
            </option>
          </select>
        </div>
      
        <div class="mt-3">
          <label for="icon" class="text-start w-100 font-bold">Icono</label>
      
          <span
            class="text-start d-block font-14 text-error"
            *ngIf="formErrors?.['icon']"
          >
            {{formErrors?.['icon']?.label}}
          </span>
      
          <input
            id="icon"
            name="icon"
            type="file"
            class="icon-input w-100"
            placeholder="Ingrese icono de la carrera"
            (change)="onFileChanged($event)"
          />

          <div class="career__icon--wrapper mt-3">
            <img
              [src]="icon.preview"
              width="200"
              height="200"
              class="career-icon"
            />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-6">
            <label for="primary_color" class="text-start w-100 font-bold">Color Primario</label>
        
            <span
              class="text-start d-block font-14 text-error"
              *ngIf="formErrors?.['primary_color']"
            >
              {{formErrors?.['primary_color']?.label}}
            </span>

            <input type="color" formControlName="primary_color" /> 
          </div>

          <div class="col-6">
            <label for="secundary_color" class="text-start w-100 font-bold">Color Secundario</label>

            <span
              class="text-start d-block font-14 text-error"
              *ngIf="formErrors?.['secundary_color']"
            >
              {{formErrors?.['secundary_color']?.label}}
            </span>
        
            <input type="color" formControlName="secundary_color" />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-6">
            <label for="job" class="text-start w-100 font-bold">Trabajo de la Carrera</label>

            <div
              id="job"
              [froalaEditor]
              [(froalaModel)]="job"
            ></div>
          </div>

          <div class="col-6">
            <label for="description" class="text-start w-100 font-bold">Descripción de la Carrera</label>

            <div
              id="description"
              [froalaEditor]
              [(froalaModel)]="description"
            ></div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <div class="d-flex flex-column">
            <label for="outputProfile" class="mt-3 font-bold">Salida del Perfil</label>

            <div
              [froalaEditor]
              id="outputProfile"
              [(froalaModel)]="outputProfile"
            ></div>
          </div>
        </div>

        <button class="btn font-btn bg-red w-100 font-26 mt-3">
          <app-loading *ngIf="isLoading" color="rgba(255, 255, 255, 1)"></app-loading>
      
          <span class="text-white" *ngIf="!isLoading">
            Crear Carrera Universitaria
          </span>
        </button>
      </form>
    </app-card>
  </div>
</main>