import { ModalComponent } from '../../core/modal/modal.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { handleFormErrors } from 'src/app/utils/handleFormErrors';

@Component({
  selector: 'app-signin-page',
  templateUrl: './signin-page.component.html',
  styleUrls: ['./signin-page.component.scss'],
  providers: [CookieService],
})

export class SigninPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  signInForm!: FormGroup
  formErrors: any = {}
  showPassword: boolean = false;
  isLoading: boolean = false;

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    })
  }

  onSubmit() {
    if (!!this.cookieService.get('bo_access')) {
      this.cookieService.deleteAll()
    }
    this.formErrors = null
    this.isLoading = true

    const { errors, totalErrors } = handleFormErrors(this.signInForm)

    if (totalErrors) {
      console.error('SignIn Form Error', errors)
      this.formErrors = errors
      this.isLoading = false
      return
    }

    const { email, password } = this.signInForm.value

    this.authService.signIn(email, password).subscribe({
      complete: () => {
        this.isLoading = false
        this.router.navigateByUrl('/dashboard')
      },
      error: (e) => {
        this.isLoading = false

        if (e.error.hasOwnProperty('detail'))
          this.modal.open('Ha ocurrido un error', e.error.detail)
        else
          this.modal.open('Ha ocurrido un error', 'Pongase en contacto con el administrador')
      },
      next: (data) => {
        this.isLoading = false
        this.cookieService.set("bo_refresh", data.refresh)
        this.cookieService.set("bo_access", data.access)
      },
    })
  }
}
