<div class="activiy-stepper">
  <!-- STEPS BADGES -->
  <div class="stepper-header d-flex align-items-start ">
    <div class="step-label cursor-pointer d-flex gap-5" *ngFor="let step of stepsList;index as i" (click)="goToStep(i)">
      <button class="btn  me-3" [hidden]="i === 1 && !isSelectable(typeSelected!)"
        [class]="{'bg-green': step.id === stepsList[currentStep].id}">
        <span class="text-green font-16 font-primary--b"
          [class]="{'text-white': step.id === stepsList[currentStep].id}">
          {{step.title}}
        </span>
      </button>
    </div>
  </div>

  <div class="stepper-body mt-4">
    <div class="step step-n">
      <!-- ERRORS -->
      <div class="row mb-3" *ngIf="errors.length">
        <div class="col">
          <div class="alert alert-danger p-3" role="alert">
            <ul>
              <li *ngFor="let error of errors">
                <p class="font-18 font-primary--b">{{error.title}}</p>

              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- STEP 1 -->
      <app-activity-form [editMode]="editMode" [activityToEdit]="activity" #activityForm [hidden]="currentStep !== 0">
      </app-activity-form>

      <!-- STEP 2 -->
      <app-create-activity-options [editMode]="editMode" #activityOptionsForm [hidden]="currentStep !== 1">
      </app-create-activity-options>
    </div>
  </div>

  <!-- Action buttons -->
  <div class="stepper-action d-flex gap-3 justify-content-end mt-3">
    <button class="btn">
      <span class="text-red" routerLink="/dashboard/actividades">volver</span>
    </button>
    <button class="btn bg-blue" *ngIf="currentStep !== 0" (click)="prevStep()">
      <span class="text-white">Anterior</span>
    </button>

    <button class="btn bg-blue" [disabled]="inProcess" [class.bg-grey]="inProcess" (click)="save()">
      <span *ngIf="!inProcess;else loading">
        <span class="text-white" *ngIf="currentStep !== 1 && isSelectable(typeSelected!)">Siguiente</span>
        <span class="text-white" *ngIf="currentStep === 1 || !isSelectable(typeSelected!)">
          <span class="text-white" *ngIf="!editMode">Guardar</span>
          <span class="text-white" *ngIf="editMode">Editar</span>
        </span>
      </span>
      <ng-template #loading>
        <app-loading></app-loading>
      </ng-template>
    </button>
  </div>
</div>
