import { Store } from '@ngxs/store';
import { PageFormComponent } from './../../core/page-form/page-form.component';
import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { Router } from '@angular/router';
import { Page } from 'src/app/models/page.models';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './new-page-page.component.html',
  styleUrls: ['./new-page-page.component.scss']
})
export class NewPagePageComponent implements OnInit, AfterViewInit {
  @ViewChild('newPageForm') newPageForm!: PageFormComponent
  three: any = []
  errors: Array<{ title: string }> = []
  inProcess: boolean = false

  constructor(private pageService: PageService, private store: Store, public router: Router) { }

  ngOnInit(): void {
    this.getThree()
  }

  ngAfterViewInit(): void {
    console.debug('{newPageForm}<PageFormComponent> ', this.newPageForm)
  }

  getThree() {
    this.inProcess = true
    this.pageService.getQuestionaries().subscribe({
      next: (data) => {
        this.three = data as Array<any>
        this.typeSetter()
        this.inProcess = false
      },
      error: (err) => {
        console.error(err)
        this.inProcess = false
        this.store.dispatch(new OpenModal('Ha ocurrido un error, pongase en contacto con el administrador'))
      },
    })
  }

  typeSetter() {
    this.three.forEach((questionary: any) => {
      questionary.type = 'questionary'
      questionary.pages.forEach((chapter: any) => {
        chapter.type = 'chapter'
        chapter.pages.forEach((theme: any) => {
          theme.type = 'theme'
          theme.pages.forEach((subtheme: any) => {
            subtheme.type = 'subtheme'
            theme.pages.forEach((activity: any) => {
              activity.type = 'activity'
            });
          });
        });
      });
    });
  }

  /**
 * Fields form validations
 * @returns
 */
  isValidForm(page: Page): boolean {
    const emptyName = page.name === ''
    const emptyType = page.type === null
    const emptyQuestionaryContainer = this.newPageForm.questionaryId === null
    const emptyChapterContainer = this.newPageForm.chapterId === null
    const emptyThemeContainer = this.newPageForm.themeId === null
    const emptySubthemeContainer = this.newPageForm.subthemeId === null
    const emptyBgImage = this.newPageForm.page.image === null
    const emptyVideo = this.newPageForm.page.video_url === null

    this.errors = []

    let isValidForm = true

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre de la página es requerido.' })
      isValidForm = false
    }

    if (emptyType) {
      this.errors.push({ title: 'El tipo de página es requerido.' })
      isValidForm = false
    }

    if (emptyQuestionaryContainer && this.newPageForm.type === 'chapter' ||
      emptyQuestionaryContainer && this.newPageForm.type === 'theme' ||
      emptyQuestionaryContainer && this.newPageForm.type === 'subtheme' ||
      emptyQuestionaryContainer && this.newPageForm.type === 'activity') {
      this.errors.push({ title: 'Debe indicar el cuestionario contenedor.' })
      isValidForm = false
    }

    if (emptyChapterContainer && this.newPageForm.type === 'theme' ||
      emptyChapterContainer && this.newPageForm.type === 'subtheme' ||
      emptyChapterContainer && this.newPageForm.type === 'activity') {
      this.errors.push({ title: 'Debe indicar el capítulo contenedor.' })
      isValidForm = false
    }

    if (emptyThemeContainer && this.newPageForm.type === 'subtheme' ||
      emptyThemeContainer && this.newPageForm.type === 'activity') {
      this.errors.push({ title: 'Debe indicar el tema contenedor.' })
      isValidForm = false
    }

    if (emptySubthemeContainer && this.newPageForm.type === 'activity') {
      this.errors.push({ title: 'Debe indicar el subtema contenedor.' })
      isValidForm = false
    }

    if (!emptyBgImage) {
      if (!this.newPageForm.isMainImageFile && !this.isImgLink(this.newPageForm.page.image!)) {
        this.errors.push({ title: 'La url de la imagen de fondo no es valida.' })
        isValidForm = false
      }
    }

    if (!emptyVideo) {
      if (!this.isVideoLink(this.newPageForm.page.video_url!)) {
        this.errors.push({ title: 'La url del video no es valida.' })
        isValidForm = false
      }
    }

    return isValidForm
  }

  addToThree(redirect: boolean = false) {
    if (this.isValidForm(this.newPageForm.page)) {
      let toastMessage = ''
      this.newPageForm.page.questionary = String(this.newPageForm.questionaryId)

      switch (this.newPageForm.type) {
        case 'chapter':
          this.newPageForm.page.parent = null
          toastMessage = `Capitulo '${this.newPageForm.page.name}' creado.`
          break;
        case 'theme':
          this.newPageForm.page.parent = this.newPageForm.chapterId
          toastMessage = `Tema '${this.newPageForm.page.name}' creado.`
          break;
        case 'subtheme':
          this.newPageForm.page.parent = this.newPageForm.themeId
          toastMessage = `Subtema '${this.newPageForm.page.name}' creado.`
          break;
        case 'activity':
          this.newPageForm.page.parent = this.newPageForm.subthemeId
          this.newPageForm.page.is_activity = true
          toastMessage = `Actividad '${this.newPageForm.page.name}' creada.`
          break;
        default:
          break;
      }

      this.pageService.newPage(this.newPageForm.page)
        .subscribe({
          next: (value: any) => {
            this.getThree()
            this.newPageForm.resetForm()
            this.store.dispatch(new OpenModal(toastMessage))
            if (redirect) {
              this.router.navigate(['/dashboard/paginas'])
            }
          },
          error: (err) => {
            this.store.dispatch(new OpenModal(`Ha ocurrido un error: '${err.message}'`))
            console.debug(err)
          },
        })
    }
  }

  isImgLink(url: string | SafeUrl) {
    if (typeof url !== 'string') return false;
    return (url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gmi) != null);
  }
  isVideoLink(url: string | SafeUrl) {
    if (typeof url !== 'string') return false;
    return (url.match(/^http[^\?]*.(mp4|flv|mkv|avi|m3u8)(\?(.*))?$/gmi) != null);
  }
}
