<main class="activities-page page">

  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <article card__content>
      <div class="row align-items-center justify-content-between">
        <div class="col">
          <h1 class="font-24 font-primary--eb"> {{ categoryParams.category_name }} </h1>
        </div>
        <div class="col-auto">
          <button class="btn" (click)="back()"><span class="text-red">Volver</span></button>
        </div>
      </div>
    </article>
  </app-card>
  <div class="sub-header mt-4 mb-3">
    <h5> Parámetros de la categoría </h5>
  </div>
  <div class="params-wrapper">
    <div class="list">
      <div class="row">
        <div class="col-4 param-item" *ngFor="let params of categoryParams.parameters">
          <div class="wrapper">
            <app-card height="100%" padding="18px 22px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100%"
              borderRadius="10px">
              <article card__content class="row">
                <div class="col-12">
                  <p class="font-16 font-primary--b">
                    {{params.label}}
                  </p>
                </div>
              </article>
            </app-card>
          </div>

        </div>
      </div>
    </div>
  </div>
</main>
