<div class="activity-wrapper">
  <div [innerHtml]="statement | safeHtml"></div>
  <hr class="separator">
  <div class="video-container mb-3" *ngIf="video">
    <app-video [options]="{
              fluid: true,
              aspectRatio: '16:9',
              autoplay: false,
              controls: true,
              sources: [{
                src: video,
                type: 'video/mp4' }]
              }">
    </app-video>
  </div>
  <ul class="list-unstyled valorations">
    <ng-container *ngFor="let option of options">
      <li class="valoration" *ngIf="option.body">
        <label class="d-block valoration__label" for="range-{{option.id}}">
          {{option.body}}
        </label>
        <input class="valoration__input" (change)="updateRange($event)" [(ngModel)]="option.value" min="0" max="5"
          [defaultValue]="option.value" type="range" id="range-{{option.id}}">
        <small *ngIf="false">
          {{option.value}}
        </small>
      </li>
    </ng-container>
  </ul>
</div>
<h3 *ngIf="!options!.length" class="font-primary text-grey font-16 opacity-50 text-center">Aun no ha provisto de
  opciones a está pregunta</h3>
