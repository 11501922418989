<main class="activities-page page">
  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content class=>
      <div class="row align-items-center justify-content-between w-100">
        <div class="col">
          <h1 class="font-24 font-primary--eb">Frases</h1>
        </div>
      </div>
    </div>
  </app-card>

  <div class="mt-3" *ngIf="isLoading">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
      </div>
    </app-card>
  </div>

  <div class="mt-3" *ngIf="!isLoading && !phrases.length">
    <app-card borderRadius="10px" padding="80px 120px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content class="text-center">
        <span class="font-24 opacity-50">
          No hay frases
        </span>
      </div>
    </app-card>
  </div>

  <ngx-masonry [options]="masonryOptions" style="margin-left: auto; margin-right: auto;" class="mt-3" [ordered]="true"
    *ngIf="!isLoading && phrases">
    <div [prepend]="false" ngxMasonryItem *ngFor="let phraseCategory of phrases; index as idx"
      style="width: 475px; margin-left: auto; margin-right: auto;" class="mt-4">
      <app-card padding="20px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100%" borderRadius="10px">
        <article card__content class="row">
          <h5 class="text-start font-bold font-20">
            {{phraseCategory[0]['parameter_category_name']}}
          </h5>

          <ul class="phraseParameter__list">
            <li *ngFor="let phraseParameter of phraseCategory" class="phraseParameter__list--item mt-2 cursor-pointer"
              [routerLink]="'/dashboard/reportes/frases/' + phraseParameter['id']">
              <span class="font-18">
                {{phraseParameter['parameter_name']}}
              </span>
            </li>
          </ul>
        </article>
      </app-card>
    </div>
  </ngx-masonry>

  <!-- <div>
      <app-card padding="20px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100%" borderRadius="10px" *ngIf="!isLoading && phrases">
        <article card__content class="row">
          <h5 class="text-start font-bold">
            Campos profesionales
          </h5>

          <ul class="phraseParameter__list">
            <li
              *ngFor="let phrase of phrases['Campos profesionales']"
              class="phraseParameter__list--item mt-2 cursor-pointer"
              [routerLink]="'/dashboard/reportes/frases/' + phrase['id']"
            >
              <span class="font-18">
                {{phrase['parameter_name']}}
              </span>
            </li>
          </ul>
        </article>
      </app-card>
    </div>

    <div> <!-
      <app-card padding="20px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100%" borderRadius="10px" *ngIf="!isLoading && phrases">
        <article card__content class="row">
          <h5 class="text-start font-bold">
            Inteligencias múltiples
          </h5>

          <ul class="phraseParameter__list">
            <li
              *ngFor="let phrase of phrases['Inteligencias múltiples']"
              class="phraseParameter__list--item mt-2 cursor-pointer"
              [routerLink]="'/dashboard/reportes/frases/' + phrase['id']"
            >
              <span class="font-18">
                {{phrase['parameter_name']}}
              </span>
            </li>
          </ul>
        </article>
      </app-card>
    </div>

    <div> <!-
      <app-card padding="20px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100%" borderRadius="10px" *ngIf="!isLoading && phrases">
        <article card__content class="row">
          <h5 class="text-start font-bold">
            Inteligencia emocional
          </h5>

          <ul class="phraseParameter__list">
            <li
              *ngFor="let phrase of phrases['Inteligencia emocional']"
              class="phraseParameter__list--item mt-2 cursor-pointer"
              [routerLink]="'/dashboard/reportes/frases/' + phrase['id']"
            >
              <span class="font-18">
                {{phrase['parameter_name']}}
              </span>
            </li>
          </ul>
        </article>
      </app-card>
    </div>

    <div>
      <app-card padding="20px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100%" borderRadius="10px" *ngIf="!isLoading && phrases">
        <article card__content class="row">
          <h5 class="text-start font-bold">
            Estilos de aprendizaje
          </h5>

          <ul class="phraseParameter__list">
            <li
              *ngFor="let phrase of phrases['Estilos de aprendizaje']"
              class="phraseParameter__list--item mt-2 cursor-pointer"
              [routerLink]="'/dashboard/reportes/frases/' + phrase['id']"
            >
              <span class="font-18">
                {{phrase['parameter_name']}}
              </span>
            </li>
          </ul>
        </article>
      </app-card>
    </div>

    <div>
      <app-card padding="20px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100%" borderRadius="10px" *ngIf="!isLoading && phrases">
        <article card__content class="row">
          <h5 class="text-start font-bold">
            Personalidad
          </h5>

          <ul class="phraseParameter__list">
            <li
              *ngFor="let phrase of phrases['Personalidad']"
              class="phraseParameter__list--item mt-2 cursor-pointer"
              [routerLink]="'/dashboard/reportes/frases/' + phrase['id']"
            >
              <span class="font-18">
                {{phrase['parameter_name']}}
              </span>
            </li>
          </ul>
        </article>
      </app-card>
    </div>
  </div> -->
</main>