import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { NewUser } from '../models/user.models';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
  ) { }

  signIn(email: string, password: string): Observable<any> {
    const URL = `${environment.apiUrl}/authentication/login`

    return this.httpClient.post(URL, { email, password }).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  signUp(user: NewUser): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/user/register`, user,).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
  logout(refreshToken: { refresh: string }): Observable<any> {
    const url = environment.apiUrl + '/authentication/logout'
    return this.httpClient.post(url, refreshToken).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  public isAuthenticated(): boolean {
    const token = this.cookieService.get('bo_access');
    // TODO: CHECK TOKEN EXPIRED
    // return !this.jwtHelper.isTokenExpired(token);
    return !!token
  }
}
