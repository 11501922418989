import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPhrase, IPhraseToUpdate } from 'src/app/models/phrase.model';
import { PhrasesService } from 'src/app/services/phrases.service';
import { ModalComponent } from '../../core/modal/modal.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-phrase-page',
  templateUrl: './edit-phrase-page.component.html',
  styleUrls: ['./edit-phrase-page.component.scss']
})
export class EditPhrasePageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  phrase!: IPhrase
  formErrors: any = {}
  isLoading: boolean = true
  isImageFile: boolean = true
  iconUrl: string = ''
  icon: { preview: any, file: any } = {
    preview: null,
    file: null
  }

  // formData
  preliminary_first_part: string = ''
  preliminary_second_part: string = ''
  final_first_part: string = ''
  final_second_part: string = ''

  constructor(
    private route: ActivatedRoute,
    private phraseService: PhrasesService,
    private router: Router,
    protected sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    const careerId = Number(this.route.snapshot.paramMap.get('id'))

    this.phraseService.getPhrasesById(careerId).subscribe({
      error: (err) => console.error('getCareerById error', err),
      next: (data: IPhrase) => {
        this.phrase = data
        this.preliminary_first_part = data.preliminary_first_part
        this.preliminary_second_part = data.preliminary_second_part
        this.final_first_part = data.final_first_part
        this.final_second_part = data.final_second_part

        if (data.icon) {
          this.icon.preview = data.icon
        }

        if (data.icon_url) {
          this.icon.preview = data.icon_url
        }
      },
      complete: () => this.isLoading = false
    })
  }

  toggleIsImageFile() {
    this.isImageFile = !this.isImageFile
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0]
      this.icon.file = file

      const reader = new FileReader()
      reader.onload = () => this.icon.preview = reader.result
      reader.readAsDataURL(file)
    }
  }

  onSubmit() {
    this.isLoading = true
    if (
      !this.preliminary_first_part ||
      !this.preliminary_second_part ||
      !this.final_first_part ||
      !this.final_second_part
    ) {
      this.modal.open('Ha ocurrido un error', 'Todos los campos son requeridos')
      this.isLoading = false
      return
    }

    console.log('this.icon', this.icon)
    console.log('this.iconUrl', this.iconUrl)

    if (this.isImageFile && !this.icon.file) {
      this.modal.open('Ha ocurrido un error', 'Faltan campos requeridos')
      this.isLoading = false
      return
    }

    
    if (!this.isImageFile && !this.iconUrl) {
      this.modal.open('Ha ocurrido un error', 'Faltan campos requeridos')
      this.isLoading = false
      return
    }

    let phraseToUpdate: IPhraseToUpdate = {
      preliminary_first_part: this.preliminary_first_part,
      preliminary_second_part: this.preliminary_second_part,
      final_first_part: this.final_first_part,
      final_second_part: this.final_second_part,
      icon: this.iconUrl || this.icon.file
    }

    this.phraseService.updatePhrase(this.phrase.id || null, phraseToUpdate).subscribe({
      error: (err) => console.error('updateCoupon error', err),
      next: (data: IPhrase) => this.phrase = data,
      complete: () => {
        this.isLoading = false
        this.router.navigate([`/dashboard/reportes/frases/${this.phrase.id}`])
      }
    })
  }
}
