<main class="activity-page page">
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">
              {{activity.title}}
              <span class="d-blocktext-grey opacity-50 font-14">&lt;{{activity.type}}&gt;</span>
            </h1>
          </div>
          <div class="col-auto off">
            <button class="btn bg-orange-alt text-white" routerLink="/dashboard/actividades/{{activityId}}/editar"
              type="button">Editar
              Actividad</button>
          </div>
        </div>
      </article>
    </app-card>
  </section>
  <section class="mt-5">
    <div class="row justify-content-center">
      <div class="col-8">
        <div [ngSwitch]="activity.type">
          <div *ngSwitchCase="'text'">
            <app-text-activity>
            </app-text-activity>
          </div>
          <div *ngSwitchCase="'video'">
            <app-video-activity [video]="'https://grapi.growthroad.es:5555/noauth/video?vid=intro_voc&ext=mp4'">
            </app-video-activity>
          </div>
          <div *ngSwitchCase="'simple-selection'">
            <app-multiple-selection-activity [isSimple]="true" [max]="1"></app-multiple-selection-activity>
          </div>
          <div *ngSwitchCase="'multiple-selection'">
            <app-multiple-selection-activity>
            </app-multiple-selection-activity>
          </div>
          <div *ngSwitchCase="'percentual-valoration'">
            <app-percentual-valoration-activity>
            </app-percentual-valoration-activity>
          </div>
          <div *ngSwitchCase="'list'">
            <app-list-activity>
            </app-list-activity>
          </div>
          <div *ngSwitchCase="'regular-valoration'">
            <app-regular-valoration-activity>
            </app-regular-valoration-activity>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>