<section>
  <article card__content *ngIf="currentActivity?.type !== 'CONTINUOUS_NUMERIC_ANSWER'">
    <div class="row justify-content-between align-items-center">
      <div class="col-8">
        <h5 class="font-24 font-primary--eb m-0">
          Crear Opciones 
        </h5>
      </div>

      <div class="col-auto d-flex">
        <span
        [ngClass]="isPonderable ? 'font-24 font-primary--eb text-green' : 'font-24 font-primary--eb text-warning'"
      >
        {{isPonderable ? 'Ponderables' : 'No Ponderables'}}
      </span>

        <label class="switch ms-3">
          <input type="checkbox" (click)="toggleIsPonderable()" [checked]="isPonderable">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-10">
        <div class="row" *ngIf="showMinMax && options.length">
          <div class="col-3">
            <label for="min" class="font-14">Mínima Elegible</label>

            <input
              id="min"
              name="min"
              type="number"
              step="1"
              min="1"
              class="form-control w-90px"
              placeholder="Mínimo"
              [max]="max"
              [(ngModel)]="min"
              (change)="handleMin($event)"
              (focusout)="handleMin($event)"
            />
          </div>

          <div class="col-3">
            <label for="max" class="font-14">Máxima Elegible</label>

            <input
              id="max"
              name="max"
              type="number"
              placeholder="Máximo"
              class="form-control w-90px"
              [min]="min"
              [(ngModel)]="max"
              [max]="options.length"
              (change)="handleMax($event)"
              (focusout)="handleMax($event)"
            />
          </div>
        </div>
      </div>

      <div class="col-2">
        <button
        type="button"
        class="btn btn-success text-white mt-2"
        (click)="addOption()"
      >
      Añadir Opción
    </button>
      </div>
    </div>

    <div class="row mb-3" *ngIf="errors.length">
      <div class="col">
        <div class="alert alert-danger p-3" role="alert">
          <ul>
            <li *ngFor="let error of errors">
              <p class="font-18 font-primary--b">{{error.title}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </article>

  <br />

  <div class="text-center" *ngIf="!options.length">
    <span class="font-24 opacity-50">
      No has creado ninguna opción aun, 
        <span
          class="text-green cursor-pointer"
          (click)="addOption()"
        >
          añade
        </span>
      una.
    </span>
  </div>

  <ul *ngIf="!isPonderable">
    <li  
      *ngFor="let option of options"
      class="col"
    >
      <div class="d-flex align-items-center justify-content-start mb-3">
        <input
          name="title"
          type="text"
          class="input w-100 col"
          [(ngModel)]="option.body || option.name"
          placeholder="Título de la opción"
        />

        <button title="Quitar Opción" type="button" class="btn col-1 p-0" (click)="removeOptionById(option.id)">
          <img class="w-30px h-30px" src="/assets/images/icons/icon_close.svg" alt="">
        </button>
      </div>
    </li>
  </ul>

  <ul *ngIf="isPonderable" class="col activity__options">
    <!-- Display Options -->
    <li *ngFor="let option of options" class="col activity__option">
      <div class="d-flex align-items-center mb-3">
        <input
          name="title"
          type="text"
          class="input w-100"
          placeholder="Título de la opción"
          [(ngModel)]="option.body"
        />

        <div class="d-flex">
          <button
            title="Mostrar Categorias"
            type="button"
            class="btn mt-0 pt-0"
            (click)="option.toggleShowCategories()"
          >
            <img
              class="w-30px h-30px"
              [src]="option.showCategories ? '/assets/images/icons/icon_up.svg' : '/assets/images/icons/icon_down.svg'"
            />
          </button>

          <button
            title="Quitar Opción"
            type="button"
            class="btn mt-0 pt-0"
            (click)="removeOptionById(option.id)"
          >
            <img class="w-30px h-30px" src="/assets/images/icons/icon_close.svg" />
          </button>
        </div>
      </div>

      <!-- Display Categories -->
      <div class="ps-4" *ngIf="option.showCategories">
        <span class="font-20 font-primary--b">
          Categorias
        </span>

        <ul class="p-0">
          <li
            *ngFor="let category of option.categories"
            class="w-100 row mt-4"
            style="border-radius: 4px; padding: 14px 8px 14px 0px; background-color: #b6ffb28c;"
          >
            <div class="text-black mt-3 font-primary--b font-18">
              {{category.label}}
            </div>

            <!-- Display Parameters -->
            <ul class="d-flex pb-2 ps-4" style="flex-wrap: wrap;">
              <li class="mt-2 me-3" *ngFor="let parameter of category.parameters">
                <div class="d-flex align-items-center justify-content-start">
                  <span>{{parameter.label}}</span>
                </div>

                <div class="d-flex align-items-center justify-content-start">
                  <input
                    name="title"
                    type="number"
                    class="input mt-1"
                    min="0.00"
                    step="0.01"
                    maxlength="5"
                    [defaultValue]="'0.00'"
                    [(ngModel)]="parameter.value"
                  />
                </div>
              </li>
            </ul>

          </li>
        </ul>
      </div>
    </li>
  </ul>
</section>
